import React,{useState, useEffect} from "react";
import { Card, CardContent, Stack, Typography } from "@mui/material";


export default ({ }) => {

    return (
        <Card variant="outlined" sx={{ width: "100%" }}>
            <CardContent>
                <Stack>
                    <Typography
                        variant="h6"
                        fontFamily={"Source Sans Pro"}
                        color={"#572682"}
                        fontWeight={600}
                    >
                        Notices
                    </Typography>

                    <ul
                        style={{
                            fontFamily: "Source Sans Pro",
                            color: "GrayText",
                        }}
                    >
                        <li>
                            Deliveries are not oparating in USA on december
                            25th due to Chrismas Holiday.
                        </li>
                        <li>
                            Deliveries are not oparating in USA this week due
                            to heavy snowfall.
                        </li>
                    </ul>
                </Stack>
            </CardContent>
        </Card>
    )
}