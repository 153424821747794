import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ButtonComponent from "../Buttons/ButtonComponent";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

export const PopupComponent = ({
  handleAction = () => {},
  handleClose = () => {},
  open,
  content,
  title,
  secondaryBtnName,
  actionButtonName,
  closeButtonOnTop = true,
  maxWidth,
  showBottomBtns = true,
  showActionbtn = true,
  showSecondaryBtn = true,
}) => {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={maxWidth}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              fontFamily={"Source Sans Pro"}
              fontWeight={600}
              color={"#572682"}
            >
              {title}
            </Typography>
            {closeButtonOnTop ? (
              <IconButton aria-label="close" onClick={handleClose}>
                <HighlightOffIcon />
              </IconButton>
            ) : null}
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent>{content}</DialogContent>
        {showBottomBtns ? (
          <DialogActions>
            {showSecondaryBtn ? (
              <ButtonComponent
                onClick={handleClose}
                variant="outlined"
                name={secondaryBtnName}
              />
            ) : null}
            {showActionbtn ? (
              <ButtonComponent
                onClick={handleAction}
                variant="contained"
                name={actionButtonName}
              />
            ) : null}
          </DialogActions>
        ) : null}
      </Dialog>
    </React.Fragment>
  );
};
