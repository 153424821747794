import Repository from "./Repository";
import _ from "lodash";

const getPath = (path) => {
  return `shipment/${path}`;
};

class ShipmentRepository extends Repository {
  checkPromoCode = async (params) => {
    try {
      const data = await this.postData(getPath(`check-promocode`), params);
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };

  onGetAdditionalShippingChargers = async () => {
      try {
          const data = await this.getData(getPath('additional-shipment-charges'), {});
          return _.get(data, `data`);
      } catch (error) {
          return { error };
      }
  };
}

export default new ShipmentRepository("Shipment");
