import React, { useState, useEffect } from "react";
import { Divider, Grid, Stack, Typography, Alert, Box } from "@mui/material";
import { ButtonComponent, PopupComponent } from "../../../atoms";
import { UploadMerchantInvoice } from "../UploadMerchentInvoice/UploadMerchantInvoice";
import _ from "lodash";
import { DashBoardRepository } from "../../../../repository";

export const ActionRequiredExpandable = ({
  data,
  onLoadDataAgain = () => {},
  onHandleBackDrop = () => {},
}) => {
  const [uploadView, setUploadView] = useState(false);
  const [allData, setAllData] = useState({});
  const [isResError, setResError] = useState(false);
  const [errorResMessage, setErrorResMessage] = useState("");
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    const item = _.get(data, "[5].item", {});
    const packageItemDetails = _.get(data, "[5].packageItemDetails", {});
    setPackages(packageItemDetails);
    setAllData({ ...item, packageItemDetails });
  }, [data]);

  const onSubmit = async (file) => {
    setResError(false);
    if (file?.name) {
      setUploadView(false);
      onHandleBackDrop(true);
      const params = {
        packageId: allData?.packageId,
        fileName: file?.name,
        documentBase64: file?.base64?.split(",")[1],
        contentType: file?.type,
      };
      const response = await DashBoardRepository.onUploadInvoice(params);
      if (response) {
        if (response?.status_code == 1) {
          onLoadDataAgain(response);
        } else {
          setResError(true);
          setErrorResMessage(_.get(response, "message", ""));
          // console.log("error response", response)
        }
        onHandleBackDrop(false);
      }
    } else {
      setResError(true);
      setErrorResMessage("Please, Select the invoice");
    }
  };

  return (
    <>
      <Stack spacing={1}>
        <Typography
          variant="subtitle1"
          fontFamily={"Source Sans Pro"}
          fontWeight={600}
          textTransform={"uppercase"}
        >
          Upload Merchant Invoice
        </Typography>

        <Typography
          variant="subtitle1"
          fontFamily={"Source Sans Pro"}
          fontWeight={500}
          color={"GrayText"}
        >
          Please{" "}
          <ButtonComponent
            variant="contained"
            name={"upload"}
            onClick={() => setUploadView(true)}
          />{" "}
          the merchant invoice for this package. When your invoice is
          successfully uploaded, your package will be placed Review until it is
          verified.
        </Typography>

        <Divider />

        <Typography
          variant="h6"
          fontFamily={"Source Sans Pro"}
          fontWeight={600}
          textTransform={"uppercase"}
        >
          Package Details
        </Typography>

        <Typography variant="subtitle1" fontFamily={"Source Sans Pro"}>
          <span style={{ fontFamily: "Source Sans Pro", fontWeight: 600 }}>
            To:
          </span>
          {` ${_.get(allData, "customerName", " ")}`}
        </Typography>
        {_.map(packages, (dataItem, index) => {
          return (
            <Box
              key={index + 1}
              sx={{
                paddingInline: 2,
                p: 1,
                borderRadius: 2,
                bgcolor: "#C8ACD630",
              }}
            >
              <Grid
                container
                spacing={2}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
              >
                <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
                  <Typography
                    variant="subtitle2"
                    fontFamily={"Source Sans Pro"}
                  >
                    <span
                      style={{ fontFamily: "Source Sans Pro", fontWeight: 600 }}
                    >
                      Description:
                    </span>
                  </Typography>

                  <Typography
                    variant="subtitle2"
                    fontFamily={"Source Sans Pro"}
                  >
                    {_.get(dataItem, "Description", "")}
                  </Typography>
                </Grid>
                <Grid item xl={2} lg={2} md={2} sm={2} xs={6}>
                  <Typography
                    variant="subtitle2"
                    fontFamily={"Source Sans Pro"}
                  >
                    <span
                      style={{ fontFamily: "Source Sans Pro", fontWeight: 600 }}
                    >
                      Qty:
                    </span>
                    <br />
                    {_.get(dataItem, "NumberOfPieces", "")}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xl={3}
                  lg={3}
                  md={3}
                  sm={3}
                  xs={6}
                  // sx={{ position: "relative" }}
                >
                        <Typography
                          variant="subtitle2"
                          fontFamily={"Source Sans Pro"}
                        >
                          <span
                            style={{
                              fontFamily: "Source Sans Pro",
                              fontWeight: 600,
                            }}
                          >
                            Value Per Unit:
                          </span>
                          <br />$ {_.get(dataItem, "ItemValueInCurrency", "")}
                        </Typography>
                </Grid>

                <Grid item xl={2} lg={2} md={2} sm={2} xs={6}>
                  <Typography
                    variant="subtitle2"
                    fontFamily={"Source Sans Pro"}
                  >
                    <span
                      style={{
                        fontFamily: "Source Sans Pro",
                        fontWeight: 600,
                      }}
                    >
                      Total Line Value:
                    </span>
                    <br />${" "}
                    {_.get(dataItem, "NumberOfPieces", "") *
                      _.get(dataItem, "ItemValueInCurrency", "")}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          );
        })}

        {/* <Grid
          container
          spacing={2}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
         
          <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
           
          </Grid>
          <Grid item xl={2} lg={2} md={2} sm={2} xs={6}>
            <Typography variant="subtitle2" fontFamily={"Source Sans Pro"}>
              Qty: {_.get(allData, "packageItemDetails[0].NumberOfPieces", "")}
            </Typography>
          </Grid>
          <Grid item xl={2} lg={2} md={2} sm={2} xs={6}>
            <Typography variant="subtitle2" fontFamily={"Source Sans Pro"}>
              Value Per Unit(
              {_.get(allData, "packageItemDetails[0].Currency", "")}):{" "}
              {_.get(allData, "packageItemDetails[0].ItemValueInCurrency", "")}
            </Typography>
          </Grid>
          <Grid item xl={2} lg={2} md={2} sm={2} xs={6}>
            <Typography variant="subtitle2" fontFamily={"Source Sans Pro"}>
              Total Line Value(
              {_.get(allData, "packageItemDetails[0].Currency", "")}):
              {_.get(allData, "totalValue", "0.00")}
            </Typography>
          </Grid>
          <Grid item xl={2} lg={2} md={2} sm={2} xs={12}></Grid>
          {isResError && (
            <Grid xs={12}>
              <Alert severity="error">{errorResMessage}.</Alert>
            </Grid>
          )}
        </Grid> */}
      </Stack>

      <PopupComponent
        open={uploadView}
        handleClose={() => setUploadView(false)}
        handleAction={() => setUploadView(false)}
        maxWidth={"md"}
        title={"Upload merchant invoice"}
        showBottomBtns={false}
        showSecondaryBtn={false}
        content={
          <UploadMerchantInvoice
            onSubmit={onSubmit}
            handleCancel={() => setUploadView(false)}
          />
        }
      />
    </>
  );
};
