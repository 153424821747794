import React, { useState } from "react";
import {
  Box,
  Card,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import {
  ButtonComponent,
  CheckboxComponent,
  InputComponent,
  RadioButtonComponent,
} from "../../atoms";
import _ from "lodash";

export const ShippingDetailsCard = ({
  shippingCost = 0,
  otherOptions = [],
  paymentMethods = [],
  handleAdditionalCost = () => {},
  onChangePromo = () => {},
  check,
  setCheck = () => {},
  promoValue,
  onClickCheck = () => {},
  isDiscount = false,
  promoCodeError = false,
  promoCodeErrorMessage = "",
  promotion = {},
  radioButton,
  handleChangeRadio=()=>{}
}) => {
  const roundToDecimalPlace = (number, decimalPlaces) => {
    const factor = Math.pow(10, decimalPlaces);
    return Math.round(number * factor) / factor;
  };

  return (
    <Card sx={{ p: 1 }}>
      <Box>
        <Typography
          textAlign={"center"}
          fontFamily={"Source Sans Pro"}
          fontWeight={600}
          variant="subtitle1"
        >
          Shipping Details & Options
        </Typography>
      </Box>
      <Divider />
      <Container>
        <Stack spacing={2} p={1}>
          <Box>
            <Box>
              <Typography
                fontWeight={600}
                fontFamily={"Source Sans Pro"}
                variant="subtitle1"
              >
                Shipping Method
              </Typography>
            </Box>
            <Grid container alignItems={"center"}>
              <Grid item xs={8}>
                <Box>
                  <RadioButtonComponent
                    selectedValue={"k"}
                    value={"k"}
                    name={"shippingMethod"}
                    label={"FedExEconomy"}
                  />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box>
                  <Typography
                    textAlign={"right"}
                    fontWeight={600}
                    fontSize={14}
                    fontFamily={"Source Sans Pro"}
                    variant="subtitle1"
                  >
                    ${roundToDecimalPlace(shippingCost, 2)}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          {_.map(otherOptions, (item, index) => {
            return (
              <Box key={index + 1}>
                <Box>
                  <Typography
                    fontWeight={600}
                    fontFamily={"Source Sans Pro"}
                    variant="subtitle1"
                  >
                    {_.get(item, "charge_group")}
                  </Typography>
                </Box>
                {_.map(_.get(item, "charge_list"), (charge, index) => {
                  return (
                    <Grid container alignItems={"center"} key={index + 1}>
                      <Grid item xs={8}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <CheckboxComponent
                            value={check ? 0 : 1}
                            onChange={(value) =>
                              handleAdditionalCost(value, _.get(charge, "cost"))
                            }
                          />
                          <Typography
                            variant="subtitle1"
                            fontFamily={"Source Sans Pro"}
                          >
                            {_.get(charge, "title")}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box>
                          <Typography
                            textAlign={"right"}
                            fontWeight={600}
                            fontFamily={"Source Sans Pro"}
                            variant="subtitle1"
                          >
                            ${_.get(charge, "cost")}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  );
                })}
              </Box>
            );
          })}

          <Box>
            <Box>
              <Typography
                fontWeight={600}
                fontFamily={"Source Sans Pro"}
                variant="subtitle1"
              >
                Payment Method
              </Typography>
            </Box>
            <Grid container alignItems={"center"}>
              <Grid item xs={8}>
                {_.map(paymentMethods, (item, index) => {
                  return (
                    <Box key={index + 1}>
                      <RadioButtonComponent
                        name={"payment"}
                        value={radioButton}
                        selectedValue={_.get(item, "slug")}
                        label={_.get(item, "slug")}
                        image={_.get(item, "image")}
                        handleChange={handleChangeRadio}
                      />
                    </Box>
                  );
                })}
              </Grid>
            </Grid>
          </Box>
          <Divider />

          <Box>
            <Box>
              <Typography
                fontWeight={600}
                fontFamily={"Source Sans Pro"}
                variant="subtitle1"
              >
                Check Discount
              </Typography>
            </Box>
            <Grid
              container
              sx={{ display: "flex", alignItems: "flex-start" }}
              spacing={1}
              pt={1}
            >
              <Grid item xs={8}>
                <InputComponent
                  isError={promoCodeError}
                  error={promoCodeErrorMessage}
                  pro
                  label={"Promo Code"}
                  value={promoValue}
                  onChange={onChangePromo}
                />
              </Grid>

              <Grid item xs={4}>
                <ButtonComponent
                  name={"Check"}
                  variant="contained"
                  onClick={onClickCheck}
                />
              </Grid>
            </Grid>
          </Box>
          {isDiscount ? (
            <Box sx={{ display: "flex" }}>
              {_.get(promotion, "type_name") == "percentage" ? (
                <Typography
                  variant="subtitle1"
                  fontWeight={600}
                  fontFamily={"Source Sans Pro"}
                >
                  You have recived a discount of {_.get(promotion, "value")}%
                </Typography>
              ) : null}

              {_.get(promotion, "type_name") == "flat" ? (
                <Typography
                  variant="subtitle1"
                  fontWeight={600}
                  fontFamily={"Source Sans Pro"}
                >
                  You have recived a discount of ${_.get(promotion, "value")}
                </Typography>
              ) : null}
            </Box>
          ) : null}

          <Box
            sx={{
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: "#B3B3B3",
              borderRadius: 2,
              bgcolor: "white",
              p: 1,
            }}
          >
            <Typography
              fontFamily={"Source Sans Pro"}
              variant="subtitle1"
              textAlign={"center"}
            >
              Estimated Shipping cost
            </Typography>
            <Typography
              fontFamily={"Source Sans Pro"}
              variant="subtitle1"
              textAlign={"center"}
            >
              ${roundToDecimalPlace(shippingCost, 2)}
            </Typography>
          </Box>

          <Box>
            <ButtonComponent
              size="large"
              variant="contained"
              name={"Confirm & Pay"}
              width={"100%"}
            />
          </Box>
        </Stack>
      </Container>
    </Card>
  );
};
