import React from "react";
import { Grid, Stack, Typography } from "@mui/material";

export const TransactionExpandable = ({
  to = "",
  carrier = "",
  weight = 0,
}) => {
  return (
    <Stack spacing={0}>
      <Typography
        variant="subtitle1"
        color={"#572682"}
        fontFamily={"Source Sans Pro"}
      >
        Requested Options
      </Typography>
      <Grid container spacing={2} justifyContent={"space-around"}>
        <Grid item>
          <Typography
            variant="subtitle1"
            color={"GrayText"}
            fontFamily={"Source Sans Pro"}
          >
            Shipping to: {to}{" "}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="subtitle1"
            color={"GrayText"}
            fontFamily={"Source Sans Pro"}
          >
            Shipping Carrier: {carrier}{" "}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="subtitle1"
            color={"GrayText"}
            fontFamily={"Source Sans Pro"}
          >
            Estimated Weight: {weight}{" "}
          </Typography>
        </Grid>
      </Grid>
    </Stack>
  );
};
