import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, Backdrop, CircularProgress, Alert } from "@mui/material";
import { ButtonComponent, InputComponent } from "../../../atoms";
import { theme } from "../../../../style/Theme/Theme";
import { MINIMUM_PASSWORD_CHARACTERS, isLowerCase, isNumber, isSpecialCharacter, isUpperCase, PASSWORD_REGEX, getText, AUTH_SCREEN_STATES } from "../../../../utils/Credentials";
import { AuthRepository } from "../../../../repository";
import _ from "lodash"

export default function ResetPasswordForm({ viewChange = () => { } }) {
  const [password, setPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [errorPassword, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('Password is required')

  const [confirmPassword, setConfirmPassword] = useState('');
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);



  const [isResError, setResError] = useState(false);
  const [errorResMessage, setErrorResMessage] = useState('');
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };


  const onReset = async () => {
    if (isPasswordValid && isConfirmPasswordValid) {
      const mobile = localStorage.getItem('phone');
      handleOpen()
      const response = await AuthRepository.onResetPassword({
        mobile,
        password,
        confirm_password: confirmPassword
      });
      if (response) {
        if (response?.status_code == 1) {
          viewChange(AUTH_SCREEN_STATES.LOGIN)
          localStorage.removeItem('phone');
        } else {
          setResError(true);
          setErrorResMessage(_.get(response, 'message', ''));
        }
        handleClose()
      } else {
        handleClose()
      }

    } else {
      if (!isPasswordValid) {
        setPasswordError(true)
      }
      if (!isConfirmPasswordValid) {
        setConfirmPasswordError(true)
      }
    }
  }


  const onConfirmPassword = (e) => {
    const text = getText(e);
    setConfirmPassword(text);
    setConfirmPasswordError(false)

    let isValid = false;
    if (new String(text).valueOf() === new String(password).valueOf()) {
      isValid = true;
    }
    setIsConfirmPasswordValid(isValid)
  }

  const onPasswordChange = (e) => {
    const text = getText(e);
    // let isValid = text.trim().length > PASSWORD_MINIMUM_CHARACTERS;
    let isMoreThanEight = false, isASpecialCharacter = false, isANumber = false, isCapitalLetter = false, isSimpleLetter = false;

    if (text?.length > 0) {
      isANumber = isNumber(text);
      isASpecialCharacter = isSpecialCharacter(text);
      isCapitalLetter = isUpperCase(text);
      isSimpleLetter = isLowerCase(text)
    }

    let message = 'Password should have'
    if (!isANumber) {
      message = message + ' a number';
    }
    if (!isASpecialCharacter) {
      message = message + ', a special characters';
    }
    if (!isCapitalLetter) {
      message = message + ', a uppercase letter';
    }
    if (!isSimpleLetter) {
      message = message + ', a lowercase letter';
    }


    let isValid = false;

    if (text.trim().length > MINIMUM_PASSWORD_CHARACTERS) {
      isMoreThanEight = true;
      // isValid = true
      isValid = PASSWORD_REGEX.test(text.trim());
    } else {
      message = message + ', a minimum 8 characters';
    }
    setPassword(text);
    setPasswordError(false);
    setConfirmPasswordError(false);
    setPasswordErrorMessage(message)
    setIsPasswordValid(isValid)
    let isConfirmeValid = false;
    if (
      new String(text).valueOf() === new String(confirmPassword).valueOf()
    ) {
      isConfirmeValid = true;
    }
    setIsConfirmPasswordValid(isConfirmeValid)
  }

  return (
    <Grid container flexDirection={"column"} spacing={2}>
      {isResError && <Grid item>
        <Alert severity="error">{errorResMessage}.</Alert>
      </Grid>}
      <Grid item>
        <Typography variant="h5" className="auth-header">
          Reset Password
        </Typography>
        <Box >
          <Typography variant="body2" color={theme.status.smallTextColor}>
            Please enter a new password with minimum of 08 characters which include uppercase and lowercase letters, numbers and special characters.
          </Typography>
        </Box>
      </Grid>

      <Grid item>
        <Grid container flexDirection={"column"} spacing={3}>
          <Grid item>
            <InputComponent
              isError={errorPassword}
              error={passwordErrorMessage}
              onChange={onPasswordChange}
              isPassword
              label="New Password"
              type={"password"}
              value={password}
            />
          </Grid>
          <Grid item>
            <InputComponent
              isError={confirmPasswordError}
              error={confirmPassword?.length == 0 ? 'Confirmed password is required' : 'Confirmed password and password are not mtched'}
              onChange={onConfirmPassword}
              isPassword
              label="Confirm Password"
              type={"password"}
              value={confirmPassword}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container spacing={1}>
          <Grid item>
            <ButtonComponent size={"large"} variant="outlined"
              // onClick={() => viewChange("rpwb")} 
              onClick={() => viewChange(AUTH_SCREEN_STATES.VERIFY)}
              name={"Back"} />
          </Grid>
          <Grid item>
            <ButtonComponent size={"large"} variant="contained" onClick={onReset} name={"Send"} />
          </Grid>
        </Grid>
      </Grid>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
}
