import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";

export default function HomePageCard({ img, text = "dsf" }) {
  return (
    <Card variant="none" sx={{ borderRadius: 2 }}>
      <CardContent>
        <Grid
          container
          flexDirection="column"
          rowGap={5}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ height: 280 }}
        >
          <Grid item>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CardMedia
                component="img"
                alt="green iguana"
                image={img}
                sx={{ backgroundSize: "contain", width: 205 }}
              />
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ width: 180, height: 80 }}>
              <Typography
                fontFamily={"Source sans pro"}
                sx={{
                  fontWeight: 600,
                  fontSize: 20,
                  color: useTheme().palette.primary.main,
                  textAlign: "center",
                }}
              >
                {text}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
