import React, { useState, useEffect } from "react";
import { Box, Container, Divider, Grid, Typography, useTheme, Backdrop, CircularProgress, Alert } from "@mui/material";
import { ButtonComponent, InputComponent } from "../../../atoms";
import { red } from "@mui/material/colors";
import { MINIMUM_PASSWORD_CHARACTERS, EMAIL_REGEX, isLowerCase, isNumber, isSpecialCharacter, isUpperCase, PASSWORD_REGEX, getText, setText } from "../../../../utils/Credentials";
import _ from "lodash";
import { AuthRepository, UserRepository } from "../../../../repository";

export default function ProfileView() {

  const [oldPassword, setOldPassword] = useState('');
  const [isOldPasswordValid, setIsOldPasswordValid] = useState(false);
  const [errorOldPassword, setOldPasswordError] = useState(false);

  const [password, setPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [errorPassword, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('Password is required')

  const [confirmPassword, setConfirmPassword] = useState('');
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);


  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [emailError, setEmailError] = useState(false);


  const [isRes, setResPose] = useState(false);
  const [errorResMessage, setErrorResMessage] = useState('');
  const [responseState, setResPonseState] = useState(false);


  const [user, setUser] = useState({});

  useEffect(() => {
    const data = localStorage.getItem('user');
    const userData = JSON.parse(data);
    const mail = _.get(userData, 'email', '');
    onEmailChange(setText(mail));
    // onOldPasswordChange(setText(''))
    setUser(userData);
  }, []);

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const onOldPasswordChange = (e) => {
    const text = getText(e);
    let isValid = false;
    isValid = text.trim().length > MINIMUM_PASSWORD_CHARACTERS;
    setOldPassword(text);
    setIsOldPasswordValid(isValid);
    setOldPasswordError(false);
  };

  const onConfirmPassword = (e) => {
    const text = getText(e);
    setConfirmPassword(text);
    setConfirmPasswordError(false)

    let isValid = false;
    if (new String(text).valueOf() === new String(password).valueOf()) {
      isValid = true;
    }
    setIsConfirmPasswordValid(isValid)
  }

useEffect(()=>{
},[oldPassword])

  const onPasswordChange = (e) => {
    const text = getText(e);
    // let isValid = text.trim().length > PASSWORD_MINIMUM_CHARACTERS;
    let isMoreThanEight = false, isASpecialCharacter = false, isANumber = false, isCapitalLetter = false, isSimpleLetter = false;

    if (text?.length > 0) {
      isANumber = isNumber(text);
      isASpecialCharacter = isSpecialCharacter(text);
      isCapitalLetter = isUpperCase(text);
      isSimpleLetter = isLowerCase(text)
    }

    let message = 'Password should have'
    if (!isANumber) {
      message = message + ' a number';
    }
    if (!isASpecialCharacter) {
      message = message + ', a special characters';
    }
    if (!isCapitalLetter) {
      message = message + ', a uppercase letter';
    }
    if (!isSimpleLetter) {
      message = message + ', a lowercase letter';
    }


    let isValid = false;

    if (text.trim().length > MINIMUM_PASSWORD_CHARACTERS) {
      isMoreThanEight = true;
      // isValid = true
      isValid = PASSWORD_REGEX.test(text.trim());
    } else {
      message = message + ', a minimum 8 characters';
    }
    setPassword(text);
    setPasswordError(false);
    setConfirmPasswordError(false);
    setPasswordErrorMessage(message)
    setIsPasswordValid(isValid)
    let isConfirmeValid = false;
    if (
      new String(text).valueOf() === new String(confirmPassword).valueOf()
    ) {
      isConfirmeValid = true;
    }
    setIsConfirmPasswordValid(isConfirmeValid)
  }

  const onUpdatePassword = async () => {
    setResPose(false)
    if (isPasswordValid && isConfirmPasswordValid && isOldPasswordValid) {

      handleOpen()
      const response = await AuthRepository.onChangePassword({
        old_password: oldPassword,
        password,
        password_confirmation: confirmPassword
      });
      if (response) {
        if (response?.status_code == 1) {
          onClean()
          setResPonseState(true)
        } else {
          setResPonseState(false)
        }
        setResPose(true);
        setErrorResMessage(_.get(response, 'message', ''));
        handleClose()
      } else {
        handleClose()
      }

    } else {
      if (!isOldPasswordValid) {
        setOldPasswordError(true)
      }
      if (!isPasswordValid) {
        setPasswordError(true)
      }
      if (!isConfirmPasswordValid) {
        setConfirmPasswordError(true)
      }
    }
  }

  const onClean = () => {
    const text = setText('');
    onOldPasswordChange(text);
    onPasswordChange(text);
    onConfirmPassword(text);
    _.delay(() => {
      setResPose(false)
    }, 15000)
  }

  const onEmailChange = (e) => {
    const text = getText(e);
    let isValid = false;
    const reg = EMAIL_REGEX;
    if (text.trim().length > 3) {
      isValid = reg.test(text.trim());
    }
    setEmail(text);
    setIsEmailValid(isValid);
    setEmailError(false);
  };

  const onEmailUpdate = async () => {
    setResPose(false)
    if (isEmailValid) {
      handleOpen()
      const response = await UserRepository.onChangeEmail({
        email
      });
      if (response) {
       
        if (response?.status_code == 1) {
          onClean()
          setResPonseState(true);
          const newUser = {...user, email};
          const userData = JSON.stringify(newUser);
          localStorage.setItem('user',userData);
        } else {
          setResPonseState(false)
        }
        setResPose(true);
        setErrorResMessage(_.get(response, 'message', ''));
        handleClose()
      } else {
        handleClose()
      }

    } else {
      setEmailError(true)
    }
  }

  return (
    <Container maxWidth={'lg'}>
      <Grid container flexDirection={"column"} rowGap={2}>
        {isRes && <Grid item>
          <Alert severity={responseState ? "success" : "error"}>{errorResMessage}.</Alert>
        </Grid>}
        <Grid item>
          <Grid container flexDirection={"column"} rowGap={2}>
            <Grid item>
              <Typography fontFamily={'Source sans pro'} fontWeight={600} color={useTheme().palette.primary.main} variant="h5">Contact Information</Typography>
            </Grid>
            <Grid item>
              <Grid container flexDirection={"column"} rowSpacing={3}>
                <Grid item>
                  <Typography fontFamily={'Source sans pro'} fontWeight={600} color={useTheme().palette.grey[700]}>Contact Number</Typography>
                  <Typography fontFamily={'Source sans pro'} color={useTheme().palette.grey[600]}>{_.get(user, 'mobile', '')}</Typography>
                </Grid>
                <Grid item>
                  {/* <Typography fontFamily={'Source sans pro'} fontWeight={600} color={useTheme().palette.grey[700]}>Email</Typography> */}
                  <InputComponent
                    isError={emailError}
                    error={email?.length > 3 ? 'Email is not valid' : 'Email is required'}
                    value={email}
                    label="Email"
                    type={'text'}
                    onChange={onEmailChange}
                  />
                </Grid>

                <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Box >
                    <ButtonComponent
                      onClick={onEmailUpdate}
                      name={'Save Changes'}
                      variant="contained" />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <Grid item>
          <Grid container flexDirection={'column'} rowGap={2}>
            <Grid item>
              <Typography fontFamily={'Source sans pro'} variant="h5" fontWeight={600} color={useTheme().palette.primary.main}>Password Settings</Typography>
            </Grid>
            <Grid item>

              <Grid container flexDirection={'column'} rowSpacing={2}>
                <Grid item>
                  <InputComponent
                    onChange={(e)=>onOldPasswordChange(e)}
                    value={oldPassword}
                    error={oldPassword?.length > 0 ? 'Please add valid password' : 'Old password is required'}
                    isError={errorOldPassword}
                    isPassword
                    label="Current Password"
                    type={'password'} />
                </Grid>
                <Grid item>
                  <InputComponent
                    onChange={onPasswordChange}
                    value={password}
                    error={passwordErrorMessage}
                    isError={errorPassword}
                    isPassword
                    label="New Password"
                    type={'password'} />
                </Grid>
                <Grid item>
                  <InputComponent
                    onChange={onConfirmPassword}
                    value={confirmPassword}
                    error={confirmPassword?.length == 0 ? 'Confirmed password is required' : 'Confirmed password and password are not mtched'}
                    isError={confirmPasswordError}
                    isPassword
                    label="Confirm Password"
                    type={'password'} />
                </Grid>
                <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Box >
                    <ButtonComponent
                      onClick={onUpdatePassword}
                      name={'Change Password'}
                      variant="contained" />

                  </Box>
                </Grid>
              </Grid>


            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>

  );
}
