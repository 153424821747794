import React from "react";
import { Avatar, Card, CardContent } from "@mui/material";


export const ViewPhoto = ({ data }) => {
  const {image, fileName} = data;
  return (
    <Card sx={{boxShadow:'none'}}>
      <CardContent>
        <Avatar src={image} sx={{ width: "100%", height:'auto', borderRadius:2 }} />
      </CardContent>
    </Card>
  );
};
