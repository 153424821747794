import { Container, Grid } from "@mui/material";
import React from "react";
import { YoutubeEmbed } from "../../components/atoms";
// import { YoutubeEmbed } from "../../components/atoms";

export const TutorialsPage = () => {
  return (
    <Container sx={{ mt: 3, mb: 3 }}>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <YoutubeEmbed embedId={"7FGE9n4bWlw"} title={"Presto® Worldwide"} />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <YoutubeEmbed
            embedId={"YFpmJHBVTxs"}
            title={"How to sign up on Presto® Worldwide"}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <YoutubeEmbed
            embedId={"jixHEhlKI9U"}
            title={"How to shop with Presto® Worldwide"}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <YoutubeEmbed
            embedId={"ushYGK0c5CA"}
            title={
              "How to ship your products with Presto® Worldwide to Sri Lanka"
            }
          />
        </Grid>
      </Grid>
    </Container>
  );
};
