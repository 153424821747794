import * as React from "react";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Fab from "@mui/material/Fab";
import { Grid, useTheme } from "@mui/material";
import { IMAGES } from "../../../assets";
import { Link } from "react-router-dom";


// const StyledFab = styled(Fab)({
//   position: "absolute",
//   zIndex: 1,
//   top: -30,
//   left: 0,
//   right: 0,
//   margin: "0 auto",
// });

export default function Footer() {
  return (
    <React.Fragment>
      <AppBar
        position="relative"
        color="primary"
        sx={{ top: "auto", bottom: 0 }}
      >
        <Toolbar>
          <Grid
            container
            flexDirection={"column"}
            justifyContent={"center"}
            rowGap={2}
            mt={5}
            mb={2}
          >
            <Grid item>
              <Grid container justifyContent={"center"} rowSpacing={2}>
                <Grid
                  item
                  xl={2}
                  lg={2}
                  md={2}
                  sm={12}
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: {
                      xl: "flex-start",
                      lg: "flex-start",
                      md: "flex-start",
                      sm: "center",
                      xs: "center",
                    },
                    rowGap: 3,
                  }}
                >
                  <img
                    src={IMAGES.LOGO.FOOTER_LOGO}
                    alt="white-logo"
                    className="footer-logo"
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: {
                        xl: "flex-start",
                        lg: "flex-start",
                        md: "flex-start",
                        sm: "center",
                        xs: "center",
                      },
                      rowGap: 1,
                    }}
                  >
                    <Typography
                      textAlign={{
                        xl: "left",
                        lg: "left",
                        md: "left",
                        sm: "center",
                        xs: "center",
                      }}
                      color={useTheme().status.footerTextColor}
                      sx={{ fontSize: 13, fontWeight: 600 }}
                    >
                      Presto Lanka (Pvt) Ltd 40 Poorwarama Road, Nugegoda, Sri
                      Lanka .
                    </Typography>
                    <Typography
                      color={useTheme().status.footerTextColor}
                      sx={{ fontSize: 13, fontWeight: 600 }}
                    >
                      Tel: +9476 440 0151
                    </Typography>
                    <Typography
                      color={useTheme().status.footerTextColor}
                      sx={{ fontSize: 13, fontWeight: 600 }}
                    >
                      Email: support@presto.lk
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                  xl={2}
                  lg={2}
                  md={2}
                  sm={3}
                  xs={12}
                >
                  <ul className="footer-ul">
                    <li>
                      <Link to={"/tutorials"}>How it works</Link>
                    </li>
                    <li>
                      <Link to={'/faq'}>FAQ</Link>
                    </li>
                    <li>
                      <Link>Prohibited Items</Link>
                    </li>
                  </ul>
                </Grid>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                  xl={2}
                  lg={2}
                  md={2}
                  sm={3}
                  xs={12}
                >
                  <ul className="footer-ul">
                    <li>
                      <Link to={'/shipping-calculator'}>Shipping Calculator</Link>
                    </li>
                    <li>
                      <Link to={'/about-us'}>About us</Link>
                    </li>
                    <li>
                      <Link to={'/contact-us'}>Contact us</Link>
                    </li>
                  </ul>
                </Grid>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                  xl={2}
                  lg={2}
                  md={2}
                  sm={3}
                  xs={12}
                >
                  <ul className="footer-ul">
                    <li>
                      <Link to={'/terms-and-conditions'}>Terms & Conditions</Link>
                    </li>
                    <li>
                      <Link to={'/privacy-policy'}>Privacy Policy</Link>
                    </li>
                  </ul>
                </Grid>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                  xl={2}
                  lg={2}
                  md={2}
                  sm={3}
                  xs={12}
                >
                  <ul className="footer-ul">
                    <li>
                      <a href={'https://www.instagram.com/prestoworldwide/'}>Instagram</a>
                    </li>
                    <li>
                      <a href={'https://www.youtube.com/channel/UCaIrt9GmYRde62dw60MJFSw'}>Youtube</a>
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={{ display: "flex", justifyContent: "center" }}>
              <Typography
                color={useTheme().status.footerTextColor}
                sx={{ fontSize: 13, fontWeight: 600 }}
                textAlign={"center"}
              >
                © 2024 Presto Lanka Pvt Ltd. All rights reserved.
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}
