import React from "react";
import { Box, Stack, Typography } from "@mui/material";

const YoutubeEmbed = ({ embedId, title }) => {
  return (
    <Stack spacing={2}>
      <Typography
        variant="h6"
        fontFamily={"Source Sans Pro"}
        fontWeight={600}
        title={title}
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: "1",
          WebkitBoxOrient: "vertical",
        }}
      >
        {title}
      </Typography>
      <iframe
        width="100%"
        height="300"
        src={`https://www.youtube.com/embed/${embedId}`}
        title={title}
        // borderRadius="20px"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
    </Stack>
  );
};

export default YoutubeEmbed;
