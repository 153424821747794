import React from "react";
import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  ButtonComponent,
  CheckboxComponent,
  DropdownComponent,
  InputComponent,
} from "../../components/atoms";

export const CreateShippingAddressPage = () => {
  return (
    <Container maxWidth="sm" sx={{ mt: 5, mb: 5 }}>
      <Grid container flexDirection={"column"} rowSpacing={2}>
        <Grid item>
          <Typography
            fontFamily={"Source sans pro"}
            fontWeight={600}
            color={useTheme().palette.primary.main}
            variant="h5"
          >
            Create Shipping Address
          </Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputComponent label="Address Name" type={"text"} />
            </Grid>
            <Grid item xs={6}>
              <InputComponent label="First Name" type={"text"} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {" "}
          <InputComponent label="Last Name" type={"text"} />{" "}
        </Grid>
        <Grid item>
          {" "}
          <InputComponent label="Address No 01" type={"text"} />
        </Grid>
        <Grid item>
          {" "}
          <InputComponent label="Address No 02" type={"text"} />
        </Grid>
        <Grid item>
          {" "}
          <InputComponent label="Phone Number" type={"text"} />{" "}
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DropdownComponent label="State or Provice" />
            </Grid>
            <Grid item xs={6}>
              <DropdownComponent label="District" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DropdownComponent label="City" />
            </Grid>
            <Grid item xs={6}>
              <DropdownComponent label="Postal Code" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CheckboxComponent />
            <Typography
              variant="subtitle1"
              sx={{
                color: useTheme().palette.grey[500],
                fontFamily: "Source sans pro",
                fontWeight: 600,
              }}
            >
              I agree to the{" "}
              <span className="hightlighted-text">Terms Of Use</span> and{" "}
              <span className="hightlighted-text">Privacy Policy</span> .
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              <ButtonComponent variant="contained" name={"Save Address"} />
            </Grid>
            <Grid item>
              <ButtonComponent variant="outlined" name={"Cancel"} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
