import React from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { ButtonComponent } from "../../components/atoms";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";

export const OrderDetailsPage = () => {
  return (
    <Container sx={{mt:3, mb:3}}>
      <Typography variant="h6" fontFamily={"Source Sans Pro"} fontWeight={600}>
        Orderd on October 23, 2019 | Order#113-0381698-9637863
      </Typography>

      <Stack spacing={2} mb={2}>
        <Grid container justifyContent={"flex-end"}>
          <Grid item>
            <ButtonComponent
              variant="contained"
              name={"View or print Invoice"}
            />
          </Grid>
        </Grid>

        <Card sx={{ borderRadius: 3 }}>
          <CardHeader title="asd" />
          <Divider />
          <CardContent>
            <Grid container spacing={4}>
              <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                <Box>
                  <Stack spacing={1}>
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      fontFamily={"Source Sans pro"}
                    >
                      Shipping Address
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      fontWeight={500}
                      fontFamily={"Source Sans pro"}
                    >
                      4283 Express Lane Suite 5866 Sarasota, FL 34249 United
                      States
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                <Box>
                  <Stack spacing={1}>
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      fontFamily={"Source Sans pro"}
                    >
                      Payment Method
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      fontWeight={500}
                      fontFamily={"Source Sans pro"}
                    >
                      visa *****4680
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                <Box>
                  <Stack spacing={1}>
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      fontFamily={"Source Sans pro"}
                    >
                      Order Summary
                    </Typography>

                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography
                        variant="subtitle1"
                        fontWeight={500}
                        fontFamily={"Source Sans pro"}
                      >
                        Item(s) Subtotal
                      </Typography>

                      <Typography
                        variant="subtitle1"
                        fontWeight={500}
                        fontFamily={"Source Sans pro"}
                      >
                        $23.00
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Accordion 1
              </AccordionSummary>
              <AccordionDetails>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
              </AccordionDetails>
            </Accordion>
          </CardContent>
        </Card>

        <Card sx={{ borderRadius: 3 }}>
          <CardHeader
            title="Deivered Friday"
            subheader={"Signed by: SWANSON"}
            action={
              <ButtonComponent variant="contained" name={"Track Package"} />
            }
          />
          <Divider />
          <CardContent>
            <Grid container spacing={2} alignItems={"center"} >
              <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                <Avatar
                  src="https://images.unsplash.com/photo-1505740420928-5e560c06d30e?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8cHJvZHVjdHxlbnwwfHwwfHx8MA%3D%3D"
                  sx={{
                    width: "100%",
                    height: 200,
                    objectFit: "contain",
                    borderRadius: 2,
                  }}
                />
              </Grid>
              <Grid item xl={7} lg={7} md={6} sm={6} xs={12}>
                <Box>
                  <Stack spacing={1}>
                    <Box>
                      <Typography
                        variant="subtitle1"
                        fontWeight={600}
                        fontFamily={"Source Sans pro"}
                      >
                        Kingston Digital 16GB 100GB USB 3.0 Data Traveler
                        (DT))G3/16GB)
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        fontWeight={500}
                        fontFamily={"Source Sans pro"}
                      >
                        Sold by: Amazon.com Services,Inc
                      </Typography>

                      <Typography
                        variant="subtitle1"
                        fontWeight={500}
                        fontFamily={"Source Sans pro"}
                      >
                        Return eligible through Nov 24,2019
                      </Typography>

                      <Typography
                        variant="subtitle1"
                        fontWeight={500}
                        fontFamily={"Source Sans pro"}
                      >
                        $3.99
                      </Typography>

                      <Typography
                        variant="subtitle1"
                        fontWeight={500}
                        fontFamily={"Source Sans pro"}
                      >
                        Condition: New
                      </Typography>

                      <Typography
                        variant="subtitle1"
                        fontWeight={500}
                        fontFamily={"Source Sans pro"}
                      >
                        Gift Options: Gift Receipt and Gift Message selected
                      </Typography>

                      <Typography
                        variant="subtitle1"
                        fontWeight={500}
                        fontFamily={"Source Sans pro"}
                      >
                        ‘Hi Aminda, Enjoy your Gift! From Senake’
                      </Typography>
                    </Box>
                    <Box sx={{ width: 150 }}>
                      <ButtonComponent
                        variant="contained"
                        name={"Buy it again"}
                      />
                    </Box>
                  </Stack>
                </Box>
              </Grid>

              <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
                <Stack spacing={1}>
                  <ButtonComponent
                    variant="contained"
                    name={"Return or replace items"}
                  />
                  <ButtonComponent
                    variant="contained"
                    name={"Share git receipt"}
                  />
                  <ButtonComponent
                    variant="contained"
                    name={"Write a product review"}
                  />
                  <ButtonComponent variant="contained" name={"Active Order"} />
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Stack>
    </Container>
  );
};
