const setUserAuthDetails = (token, userRole, userId) => {
    localStorage.setItem("token", token);
    localStorage.setItem("user_role", userRole);
    localStorage.setItem("user_id", userId);
  };
  
  const getUserRole = () => {
    return localStorage.getItem("user_role");
  };
  
  const getCurrentUserId = () => {
    return localStorage.getItem("user_id");
  };
  
  const getToken = () => {
    return localStorage.getItem("token");
  };
  
  const clearUser = () => {
    localStorage.clear()
  };
  
const UTIL_METHODS = {
    setUserAuthDetails,
    getUserRole,
    getCurrentUserId,
    getToken,
    clearUser
}

export default UTIL_METHODS;