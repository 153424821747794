import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  Backdrop,
  CircularProgress,
  Alert,
  Card,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Grid,
  IconButton,
  Stack,
  useTheme,
} from "@mui/material";
import TableComponent from "../../components/atoms/Table/TableComponent";
import HelpIcon from "@mui/icons-material/Help";
import { IMAGES } from "../../assets";
import Popover from "@mui/material/Popover";
import ButtonComponent from "../../components/atoms/Buttons/ButtonComponent";
import {
  InputComponent,
  LableValueCardlet,
  PopupComponent,
  SlideShowComponent,
} from "../../components/atoms";
import { useNavigate } from "react-router-dom";
import _, { forEach } from "lodash";
import { CommonRepositoty, DashBoardRepository } from "../../repository";
import { onGetCurrencySymble } from "../../utils/Credentials";
import {
  ErrorMessage,
  InfoMessage,
  MySuitTab,
  ProhibitedItems,
} from "../../components/organisms";
import {
  NoticeCard,
  ShipRequestCard,
  TabStyleCard,
} from "../../components/modules";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
// import FlagCircleIcon from '@mui/icons-material/FlagCircle';
// import FlagCircleTwoToneIcon from '@mui/icons-material/FlagCircleTwoTone';
import FlagCircleOutlinedIcon from "@mui/icons-material/FlagCircleOutlined";
import { baseURL } from "../../repository/Repository";

function CustomTab(props) {
  const { label, customContent, ...other } = props;

  return (
    <Tab
      {...other}
      label={
        <div>
          <Grid container spacing={1} alignItems={"center"}>
            <Grid item>
              <Typography
                textTransform={"capitalize"}
                fontWeight={600}
                fontFamily={"Source sans pro"}
              >
                {label}
              </Typography>
            </Grid>
            <Grid item>{customContent}</Grid>
          </Grid>
        </div>
      }
    />
  );
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function MySuitPage() {
  const [value, setValue] = React.useState(0);
  const [readyToSend, setReadyToSend] = React.useState(2);
  const [actionRequired, setActionRequired] = React.useState(3);
  const [inReview, setInReview] = React.useState(4);
  const [viewAll, setViewAll] = React.useState(4);
  const [ViewPopUp, setViewPopUp] = React.useState(false);

  const [isResError, setResError] = useState(false);
  const [errorResMessage, setErrorResMessage] = useState("");

  const [isSuccesRes, setIsSuccessRes] = useState(false);
  const [successResMessage, setSuccessResMessage] = useState("");

  const [isOpen, setisOpen] = React.useState(false);

  const [readyToSendData, setReadyToSendData] = useState([]);
  const [inReviewData, setInReviewData] = useState([]);
  const [actionRequiredData, setActionRequiredData] = useState([]);
  const [viewAllData, setViewAllData] = useState([]);
  const [totalWeight, setTotalWeight] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);
  const [packageCount, setPackageCount] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  const [bannerImage, setBannerImage] = useState({});
  const [unit, setUnit] = useState("");
  const [suiteNo, setSuiteNo] = useState("");
  const [data, setData] = useState({});
  const [isInfo, setIsInfo] = useState(false);
  const [infoMsg, setInfoMsg] = useState("");

  const onPopupHide = () => {
    setViewPopUp(false);
  };

  const onHandleClose = () => {
    setisOpen(false);
  };
  const onHandleOpen = () => {
    setisOpen(true);
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    setSuiteNo(_.get(user, "suite_no"));
    setData(user);
    if (_.get(user, "login_count") > 1) {
      setViewPopUp(false);
    } else {
      setViewPopUp(true);
    }

    onGetPackages();
    getBannerImage();
  }, []);

  const onGetPackages = async () => {
    setResError(false);
    const response = await DashBoardRepository.onGetPackageDetails();
    onHandleOpen();
    if (response) {
      if (response?.status_code == 1) {
        const list = _.map(_.get(response, "data", []), (value, index) => {
          const item = _.get(value, "packageInfo", {});

          const packageItemDetails = JSON.parse(
            _.get(item, "packageItemDetails", "")
          );
          const chargeDetails = JSON.parse(_.get(item, "chargeDetails", ""));
          const packageProblemDetails = JSON.parse(
            _.get(item, "packageProblemDetails", "")
          );
          const amount = _.get(item, "totalValue");
          const total = `${onGetCurrencySymble(
            _.get(packageItemDetails, "[0]Currency", "USD")
          )} ${amount}`;
          const problemTypeDescription = _.get(
            packageProblemDetails,
            "[0].ProblemTypeDescription",
            ""
          );
          const all = { packageItemDetails, item };

          return {
            ...value,
            ...item,
            weightWithUnit: `${_.get(item, "weight", "")} ${_.get(
              item,
              "weightUnit",
              ""
            )}`,
            packageItemDetails,
            chargeDetails,
            packageProblemDetails,
            total,
            amount,
            problemTypeDescription,
            all,
          };
        });
        const inReview = _.filter(
          list,
          (item, index) => item?.packageStatus == "In Review"
        );
        const readyToSend = _.filter(
          list,
          (item, index) => item?.packageStatus == "Ready To Send"
        );
        const actionRequired = _.filter(
          list,
          (item, index) => item?.packageStatus == "Action Required"
        );

        setInReviewData(inReview);
        setActionRequiredData(actionRequired);
        setViewAllData(list);
        setReadyToSendData(readyToSend);
      } else {
        setResError(true);
        setErrorResMessage(_.get(response, "message", ""));
      }
      onHandleClose();
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const navigate = useNavigate();

  const dateCount = (date) => {
    const moment = require("moment");
    const givenDate = moment(date);
    const currentDate = moment();
    return currentDate.diff(givenDate, "days") + 1;
  };

  const readyToSendColumn = [
    {
      name: "packageStatus",
      label: "State",
      options: {
        display: false,
      },
    },
    {
      name: "packageItemDetails",
      label: "all",
      options: {
        display: false,
      },
    },
    {
      name: "packageResources",
      label: "packageResources",
      options: {
        display: false,
      },
    },
    {
      name: "all",
      label: "all",
      options: {
        display: false,
      },
    },

    {
      name: "id",
      label: " ",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {_.map(tableMeta.rowData[1], (item, index) => {
                return (
                  <>
                    {item?.Prohibited ? (
                      <Box sx={{ color: "red" }}>
                        <FlagCircleOutlinedIcon fontSize="large" />
                      </Box>
                    ) : null}
                  </>
                );
              })}
            </>
          );
        },
      },
    },

    {
      name: "etailerName",
      label: "From",
    },
    {
      name: "packageId",
      label: "Package ID",
    },
    {
      name: "packageArrivalDateAtWarehouse",
      label: "Date Recived",
    },
    {
      name: "weightWithUnit",
      label: "Weight",
    },
    {
      name: "total",
      label: "Total Value",
    },
    {
      name: "id",
      label: "id",
      options: {
        display: false,
      },
    },

    {
      name: "packageArrivalDateAtWarehouse",
      label: "From",
      options: {
        customHeadLabelRender: ({ display, column }) => {
          return (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AccessAlarmIcon />
            </Box>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{dateCount(value)}</>;
        },
      },
    },
  ];

  const inReviewColumn = [
    {
      name: "packageStatus",
      label: "State",
      options: {
        display: false,
      },
    },
    {
      name: "etailerName",
      label: "From",
    },
    {
      name: "packageId",
      label: "Package ID",
    },
    {
      name: "packageArrivalDateAtWarehouse",
      label: "Date Recived",
    },
    {
      name: "weightWithUnit",
      label: "Weight",
    },
    {
      name: "total",
      label: "Total Value",
    },
  ];

  const actionRequiredColumn = [
    {
      name: "packageStatus",
      label: "State",
      options: {
        display: false,
      },
    },
    {
      name: "etailerName",
      label: "From",
    },
    {
      name: "packageId",
      label: "Package ID",
    },
    {
      name: "packageArrivalDateAtWarehouse",
      label: "Date Recived",
    },
    {
      name: "problemTypeDescription",
      label: "Action Required",
    },

    {
      name: "all",
      label: "all",
      options: {
        display: false,
      },
    },
  ];

  const viewAllColumns = [
    {
      name: "packageStatus",
      label: "State",
      options: {
        display: false,
      },
    },
    {
      name: "etailerName",
      label: "From",
    },
    {
      name: "packageResources",
      label: "packageResources",
      options: {
        display: false,
      },
    },
    {
      name: "all",
      label: "all",
      options: {
        display: false,
      },
    },
    {
      name: "packageId",
      label: "Package ID",
    },
    {
      name: "packageArrivalDateAtWarehouse",
      label: "Date Recived",
    },
    {
      name: "weightWithUnit",
      label: "Weight",
    },
    {
      name: "total",
      label: "Total Value",
    },
    {
      name: "packageStatus",
      label: "Status",
    },

    {
      name: "packageResources",
      label: "packageResources",
      options: {
        display: false,
      },
    },
    {
      name: "all",
      label: "all",
      options: {
        display: false,
      },
    },

    {
      name: "id",
      label: "id",
      options: {
        display: false,
      },
    },
  ];

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onHandleBackDrop = (value) => {
    if (value) {
      onHandleOpen();
    } else {
      onHandleClose();
    }
  };

  const onLoadDataAgain = (res) => {
    setIsSuccessRes(true);
    setSuccessResMessage(_.get(res, "message", ""));
    _.delay(() => {
      setIsSuccessRes(false);
    }, 5000);
    onGetPackages();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const getBannerImage = async () => {
    const response = await CommonRepositoty.onGetBannerImage();
    if (response?.status_code == 1) {
      setBannerImage(_.get(response, "data", {}));
    } else {
      setResError(true);
      setErrorResMessage(_.get(response, "message", ""));
    }
  };

  const getShippingCost = async (totalWeight, unit, dateCount) => {
    const response = await CommonRepositoty.onGetShippingCost({
      packageFrom: "USA",
      unit: unit,
      weight: totalWeight,
    });
    if (response?.status_code == 1) {
      const penaltyAmount = 0;
      if (dateCount >= 30) {
        let total =
          parseFloat(_.get(response, "data.rates[0].shippingCost", [])) +
          penaltyAmount;
        setShippingCost(total);
      } else {
        setShippingCost(_.get(response, "data.rates[0].shippingCost", []));
      }
    } else {
      setResError(true);
      setErrorResMessage(_.get(response, "message", ""));
    }
  };

  const selectedRows = (index, dataIndex) => {
    let isFlaged = false;
    const selectRowData = _.map(dataIndex, (item) => {
      forEach(readyToSendData[item.dataIndex].packageItemDetails, (arrItem) => {
        if (_.get(arrItem, "Prohibited")) {
          isFlaged = true;
          return true;
        }
      });

      if (isFlaged) {
        setIsInfo(true);
        setInfoMsg(
          "Selected packages have unshippable items (in flagged packages). Please remove the flagged packages in your selection."
        );
      } else {
        return { ...readyToSendData[item.dataIndex] };
      }
    });

    if (!isFlaged) {
      if (selectRowData.length > 0) {
        setPackageCount(selectRowData.length);
        setSelectedItems(selectRowData);

        let count = 0;
        selectRowData.forEach((item, index) => {
          count += parseFloat(item.weight);
          if (index === selectRowData.length - 1) {
            let datecount = dateCount(item?.packageArrivalDateAtWarehouse);
            setUnit(item.weightUnit === "L" ? "lbs" : "kg");
            getShippingCost(
              count,
              item.weightUnit === "L" ? "lbs" : "kg",
              datecount
            );
            setTotalWeight(count);
          }
        });
      } else {
        setTotalWeight(0);
        setShippingCost(0);
        setPackageCount(0);
        setSelectedItems([]);
      }
    }
  };

  return (
    <Container sx={{ mt: 5, mb: 5 }}>
      <Stack spacing={4}>
        {isResError && (
          <Grid item>
            <Alert severity="error">{errorResMessage}.</Alert>
          </Grid>
        )}
        {!isResError && isSuccesRes && (
          <Grid item>
            <Alert severity={"success"}>{successResMessage}.</Alert>
          </Grid>
        )}
        <Grid
          container
          columnSpacing={1}
          rowSpacing={1}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <Grid
              container
              columnGap={3}
              justifyContent={"space-between"}
              alignItems={"center"}
              sx={{
                borderStyle: "solid",
                borderRadius: 3,
                borderColor: useTheme().palette.grey[300],
                borderWidth: 1,
                bgcolor: "#fff",
                height: "100%",
                p: 2,
              }}
            >
              <Grid item xl={2} lg={4} md={2} sm={4} xs={4}>
                <img
                  src={IMAGES.MYSUIT.US_FLAG}
                  alt="flag"
                  style={{ height: 66, width: "100%", objectFit: "contain" }}
                />
              </Grid>
              <Grid item flexGrow={1}>
                <Stack spacing={1}>
                  <Typography
                    fontFamily={"Source sans pro"}
                    variant="h6"
                    color={useTheme().palette.primary.main}
                    fontWeight={800}
                  >
                    Packages in USA
                  </Typography>
                  <Typography fontFamily={"Source sans pro"}>
                    Suite {suiteNo}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            order={{ xl: 0, lg: 0, md: 0, sm: 1, xs: 1 }}
          >
            <Card
              variant="outlined"
              sx={{ width: "100%", borderStyle: "none" }}
            >
              <CardMedia
                className="learn-how-banner"
                sx={{ height: 100, width: "100%" }}
                image={baseURL + _.get(bannerImage, "image_path")}
                title="green iguana"
              />
            </Card>
          </Grid>

          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <Grid
              container
              sx={{
                p: 2,
                borderStyle: "solid",
                borderRadius: 3,
                borderColor: useTheme().palette.grey[400],
                borderWidth: 1,
                bgcolor: "#fff",
              }}
            >
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Typography fontFamily={"Source sans pro"}>
                      {_.get(data, "address", "")}
                    </Typography>
                    <Typography fontFamily={"Source sans pro"}>
                      Suite {suiteNo}
                    </Typography>
                  </Box>
                  <Box>
                    <IconButton aria-label="delete" onClick={handleClick}>
                      <HelpIcon fontSize="medium" color="primary" />
                    </IconButton>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "center",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <Box sx={{ p: 2, width: 240, borderRadius: 5 }}>
                        <Typography fontFamily={"Source sans pro"}>
                          Use this USA address when you checkout while shopping
                          on your favourite US retail websites.
                        </Typography>
                      </Box>
                    </Popover>
                  </Box>
                </Box>
                <Typography fontFamily={"Source sans pro"}>
                  {`PH : ${_.get(data, "phone_number", "")}`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              centered
            >
              <CustomTab
                label={"Ready to send"}
                customContent={
                  <TabStyleCard
                    size={_.get(readyToSendData, "length", 0)}
                    index={readyToSend}
                  />
                }
              />
              <CustomTab
                label={"In Review"}
                customContent={
                  <TabStyleCard
                    size={_.get(inReviewData, "length", 0)}
                    color="#ff7600"
                    index={inReview}
                  />
                }
              />

              <CustomTab
                label={"Action Required"}
                customContent={
                  <TabStyleCard
                    size={_.get(actionRequiredData, "length", 0)}
                    color="#e80000"
                    index={actionRequired}
                  />
                }
              />

              <CustomTab
                label={"View All"}
                customContent={
                  <TabStyleCard
                    size={_.get(viewAllData, "length", 0)}
                    color="#572682"
                    index={viewAll}
                  />
                }
              />

              <CustomTab
                label={"Shipped"}
                onClick={() =>
                  navigate("/history/queue", { state: { tab: "queue" } })
                }
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <MySuitTab
              columns={readyToSendColumn}
              title="Ready to Send"
              data={readyToSendData}
              selectableRows={"multiple"}
              expandableRows={true}
              onHandleBackDrop={onHandleBackDrop}
              onLoadDataAgain={onLoadDataAgain}
              onRowSelectionChange={(index, dataIndex) =>
                selectedRows(index, dataIndex)
              }
              packages={packageCount}
              totalShippingCost={shippingCost}
              totalWeight={totalWeight}
              seletedItems={selectedItems}
              weightUnit={unit}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <MySuitTab
              title="In Review"
              columns={inReviewColumn}
              data={inReviewData}
              selectableRows={"none"}
              expandableRows={false}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <MySuitTab
              title="Action Required"
              columns={actionRequiredColumn}
              data={actionRequiredData}
              selectableRows={"none"}
              expandableRows={true}
              onHandleBackDrop={onHandleBackDrop}
              onLoadDataAgain={onLoadDataAgain}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <MySuitTab
              title="View All"
              columns={viewAllColumns}
              data={viewAllData}
              selectableRows={"none"}
              expandableRows={true}
              onHandleBackDrop={onHandleBackDrop}
              onLoadDataAgain={onLoadDataAgain}
            />
          </CustomTabPanel>
        </Box>

        {/* <Grid container flexDirection={"column"} spacing={2}>
          <Grid item xl={6} lg={6} md={6} sm={7} xs={12}>
            <Typography
              fontFamily={"Source sans pro"}
              color={useTheme().status.smallTextColor}
            >
              Check the status of a package that has been delivered to Presto
              Worldwide but is not visible in your suite.
            </Typography>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Grid container spacing={1} alignItems={"center"}>
              <Grid item>
                <InputComponent
                  type={"text"}
                  label="Merchant Tracking Number"
                />
              </Grid>
              <Grid item>
                <ButtonComponent name={"Check"} variant="contained" />
              </Grid>
            </Grid>
          </Grid>
        </Grid> */}

        <SlideShowComponent />
      </Stack>

      <PopupComponent
        open={ViewPopUp}
        handleClose={onPopupHide}
        handleAction={onPopupHide}
        maxWidth={"md"}
        title={"What You Cannot Ship"}
        showBottomBtns={true}
        showSecondaryBtn={false}
        actionButtonName={"Agree"}
        content={<ProhibitedItems />}
      />
      <PopupComponent
        maxWidth={"md"}
        actionButtonName={"OK"}
        content={<ErrorMessage message={errorResMessage} />}
        showSecondaryBtn={false}
        open={isResError}
        title={"Error"}
        handleClose={() => setResError(false)}
        handleAction={() => setResError(false)}
      />

      <PopupComponent
        maxWidth={"xs"}
        actionButtonName={"OK"}
        content={<InfoMessage message={infoMsg} />}
        showSecondaryBtn={false}
        open={isInfo}
        title={"Important Message"}
        handleClose={() => setIsInfo(false)}
        handleAction={() => setIsInfo(false)}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isOpen}
        onClick={onHandleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}
