import React from "react";
import { Box, Grid, Typography } from "@mui/material";

export const AlertConfirmation = ({message=''}) => {
  return (
    <Grid container alignItems={"center"} justifyContent={"center"}>
      <Grid item>
        <Box>
          <Typography
            variant="h6"
            textAlign={"center"}
            color={"#686D76"}
            fontWeight={600}
            fontFamily={"Source Sans Pro"}
          >
            {message}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
