import axios from "axios";
import _ from "lodash";

const isLive = true;
export const baseURL = isLive ?  'https://api.prestoworldwide.com/'
// `https://liveapi.presto.archnix.dev/` 
: `https://api.presto.archnix.dev/`  ;


export default class Repository {

  url =  `${baseURL}api/`;
  IMAGE_URL = `${baseURL}storage/` ;

  constructor() {
    // this.API_NAME = config.API_NAME;
    // this.API=API;
  }


  data = async (method, path, data = null, user_token = '') => {
    let access_token = localStorage.getItem('token')
    if (_.isNull(access_token)) {
      access_token = '';
    }

    const token = ''
    return axios({
      method: method,
      url:this.url + path,
      data: data,
      headers: {
        Authorization: access_token.length > 0 ? `Bearer ${access_token}` : `Bearer`,
        Accept: "application/json",
      }
    })
      .then((response) =>  response)
      .catch((e) => {
       
        return e.response;
      });
  };


  getAxios = async (path) => {
    return axios.get(path);
  }

  getData = (path, data, token = '') => {
    return this.data("get", path, data, token);
  };

  postData = (path, data, token = '') => {
    return this.data("post", path, data, token);
  };

  putData = (path, data, token = '') => {
    return this.data("put", path, data, token);
  };

  deleteData = (path, data, token = '') => {
    return this.data("delete", path, data, token);
  };

  patchData = (path, data, token = '') => {
    return this.data("patch", path, data, token);
  };

  

  uploadForm = async (path, fd, user_token = '') => {
    const token = localStorage.getItem('token');
  
    if (_.isNull(token)) {
      token = '';
    }
    const url = this.url + path;
    try {
      const response = await axios.post(url, fd, {
        headers: {
          Authorization: token.length > 0 ? `Bearer ${token}` : `Bearer`,
          Accept: "application/json",
        },
      });
      return response;
    } catch (error) {
      return error.response;
    }
  }
}

