import React from "react";
import { Footer, Navbar } from "../components/organisms";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";

export default function Screen({isAuth=false}) {
  return (
    <>
      <Navbar isLogged={isAuth}/>
      <Outlet />
      <Box position={'relative'} sx={{ top: "auto", bottom: 0 }}>
      <Footer />
      </Box>
      
    </>
  );
}
