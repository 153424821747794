import {
  Grid,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  Box,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";


export default function DropzoneComponent({
  onChange = () => { },
  clean = false,
}) {
  const [img, setImg] = useState(null);
  const [imgPath, setImgPath] = useState("");
  const [isUploadedImg, setIsUploadedImg] = useState(false);
  const [isShow, setShow] = useState(false);

  const getBase64 = file => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object

        baseURL = reader.result;
        // console.log(baseURL);
        resolve(baseURL);
      };
      // console.log(fileInfo);
    });
  };

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];

    if (file && file?.path) {

      isShowImgae(file);
      getBase64(file)
        .then(result => {
          file["base64"] = result;
          setImg(file);
          const objectUrl = URL.createObjectURL(file);
          setImgPath(objectUrl);
          setIsUploadedImg(true);
          onChange(file);
        }).catch(err => {
          console.log(err);
        })

    }
  }, []);


  const isShowImgae = (file) => {
    const type = file?.name?.split(".")[1];
    const isShowValue = type.includes('png')
      || type.includes('jpg')
      // || type.includes('svg')
      // || type.includes('HEIC')
      || type.includes('jpeg')
      // || type.includes('tif')
      // || type.includes('gif')
      // || type.includes('apng')
      || type.includes('bmp')
      // || type.includes('webp')
      // || type.includes('jfif')
      // || type.includes('pjpeg')
      // || type.includes('pjp')
      || type.includes('pdf');

    setShow(isShowValue);
  }

  useEffect(() => {
    if (clean) {
      setImg(null);
      setImgPath("");
      setIsUploadedImg(false);
    }
  }, [clean]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <Card>
          <CardContent>
            <Typography
              variant="subtitle1"
              color={"GrayText"}
              fontFamily={"Source Sans Pro"}
              fontWeight={600}
            >
              Drop your file here
            </Typography>
          </CardContent>
        </Card>
      ) : imgPath?.length > 2 ? (
        <Grid container>
          <Grid item>
            <Card>
              {isShow ? (
                <CardMedia
                  sx={{ height: 100, width: 150 }}
                  image={imgPath}
                  title="green iguana"
                />
              ) : null}
              <CardContent>
                <Box sx={{ width: 200 }}>
                  <Typography
                    title={img?.name}
                    sx={{ width: 200 }}
                    variant="subtitle2"
                    textAlign={"center"}
                    color="text.secondary"
                  >
                    {img?.name}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
            {/* <img src={imgPath} alt={`upload-forum-img`} /> */}
          </Grid>
        </Grid>
      ) : (
        <Card>
          <CardContent>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <AttachFileOutlinedIcon />
              <Typography
                variant="subtitle1"
                color={"GrayText"}
                fontFamily={"Source Sans Pro"}
                fontWeight={600}
              >
                Click or drag and grop files here
              </Typography>
            </Box>
          </CardContent>
        </Card>
      )}
    </div>
  );
}
