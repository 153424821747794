import { Card, CardMedia } from "@mui/material";
import React from "react";
import { IMAGES } from "../../../assets";
import { Link } from "react-router-dom";

export default function LogoCard({ image, title, link }) {
  return (
    <Card variant="outlined" sx={{ borderStyle: 'none', p: 3, bgcolor: '#f0f0f0', width: 160, borderRadius: 3 }}>
      <Link to={link}>
        <CardMedia
          sx={{ height: 160 , backgroundSize:'contain'}}
          image={image}
          title={title}
        /></Link>

    </Card>
  );
}
