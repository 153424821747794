import Repository from "./Repository";
import _ from "lodash";

const getPath = (path) => {
    return `package/${path}`;
}
//Common API Calls
class DashBoardRepository extends Repository {
    onGetPackageDetails = async (params) => {
        try {
            const data = await this.getData(getPath('all-packages'), params);
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };


    onUpdatePackagePrice = async (params) => {
        try {
            const data = await this.postData(getPath('save-declared-value'), params);
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

    onUploadInvoice = async (params) => {
        try {
            const data = await this.postData(getPath('upload-invoice'), params);
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

    onGetSpecialRequestChargers = async (params) => {
        try {
            const data = await this.getData(getPath('get-special-request-charges'), params);
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

    onGetActivePaymentGateways = async (params) => {
        try {
            const data = await this.getData(getPath('payment-gateways'), params);
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

    

    
}

export default new DashBoardRepository("dashboard");