import { FormControl, InputLabel, MenuItem, Select, FormHelperText } from "@mui/material";
import React from "react";
import _ from "lodash";

export default function DropdownComponent({
  label = "Outline",
  error,
  isError,
  value,
  onChange = () => { },
  dropdownList = [],
  disabled = true
}) {
  return (
    <div className="dropdown-container">
      <FormControl fullWidth>
        <InputLabel error={isError} disabled={disabled} size="small" id="demo-simple-select-label">{label}</InputLabel>
        <Select
          error={isError}
          disabled={disabled}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label={label}
          onChange={onChange}
          size="small"
        >
          {_.map(dropdownList, (item, index) => {
            return (
              <MenuItem key={`${label}-${index}`} value={_.get(item, 'id', '-1')}>{_.get(item, 'name', '')}</MenuItem>
            )
          })
          }
        </Select>
      </FormControl>
      {isError ? <FormHelperText error={isError} id="outlined-weight-helper-text">{error}</FormHelperText> : null}
    </div>
  );
}
