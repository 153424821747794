import React from 'react';
import { CREDENTIALS, UTIL_METHODS } from '../../utils';
import { Navigate, Outlet } from 'react-router-dom';

export default function PublicRoute() {
    let role = UTIL_METHODS.getUserRole();
   
    return (
      <>{role == CREDENTIALS.PUBLIC_USER || role == null ? <Outlet/> : <Navigate to="/404" />}</>
    );
}
