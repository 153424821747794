import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Avatar, Box, Typography } from "@mui/material";
import { baseURL } from "../../../repository/Repository";

export default function RadioButtonComponent({
  label,
  image,
  fontSize,
  selectedValue,
  handleChange,
  radioButtonName,
  value
}) {
  return (
    <Box sx={{ display: "flex",gap:1, alignItems: "center"}}>
      <Radio
        checked={selectedValue == value}
        onChange={handleChange}
        value={value}
        name={radioButtonName}
        inputProps={{ "aria-label": "A" }}
        
        
      />
      {image ? <Avatar src={baseURL + image} alt="image" sx={{ width: 30,borderRadius:0, height: 20 }} /> : null}
      
      <Typography variant="subtitle1" fontFamily={"Source Sans Pro"}>
        {label}
      </Typography>
    </Box>
  );
}
