import React from "react"
import { Box, Typography } from "@mui/material"

export default ({index=0, color='#9cd600', size=0}) => {

    return (
        <Box
            sx={{
                bgcolor: index > 0 ? color : "#fff",
                paddingInline: 1,
                borderRadius: 50,
                borderStyle: index > 0 ? "none" : "solid",
                borderColor: index > 0 ? "none" : "GrayText",
                borderWidth: 1,
            }}
        >
            <Typography
                fontWeight={index ? 600 : 400}
                color={index > 0 ? "#fff" : "#572682"}
                variant="caption"
            >
                {size < 10 ? `0${size}` : size}
            </Typography>
        </Box>
    )
}

