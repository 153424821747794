import { Box, Grid, Stack, Typography, Alert } from "@mui/material";
import React, { useState } from "react";
import { ButtonComponent, DropzoneComponent } from "../../../atoms";
import _ from "lodash";

export const UploadMerchantInvoice = ({ handleCancel = () => { }, onSubmit = () => { } }) => {
  const [file, setFile] = useState(null);
  const [isError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Please select a file')

  const onChange = (file) => {
    if (file?.name && typeof file.name != "undefined" && file?.size) {

      if (isGreaterThanMaxSize(file) && isAllowFile(file)) {
        setFile(file);
        setError(false)
      }

    } else {
      setErrorMessage('Please select a valid file')
      setError(true)
    }
  }

  const isIncluded = (type)=>{
    return  type.includes('image/jpg') || type.includes('application/pdf')  || type.includes('image/png') ||type.includes('image/bmp' )||type.includes('image/tif') 
  }

  const isAllowFile = (file) => {
    const type = _.get(file,'type','')
    const isCorrectType = isIncluded(type);
    if (!isCorrectType) {
      setErrorMessage(`Allowed file types are image/png, image/bmp, image/jpg, application/pdf, This file type is ${type}`)
      setError(true)
    }
    return isCorrectType;
  }

  const isGreaterThanMaxSize = (file) => {
    const filesize = ((file?.size / 1024) / 1024).toFixed(4);
    const isSmall = filesize <= 5;
    if (!isSmall) {
      setErrorMessage(`Maximum file size is 5Mb, ${file?.name} is ${filesize}Mb`)
      setError(true)
    }
    return isSmall
  }

  const onClick = () => {
    if (!_.isNull(file)) {
      onSubmit(file)
    } else {
      setError(true)
    }
  }


  return (
    <Stack spacing={2}>
      <Box>
        <DropzoneComponent onChange={onChange} />
        <Typography variant="subtitle1" fontFamily={"Source Sans Pro"}>
          Maximum file size: 5MB (Supported file types: pdf, jpg, png, bmp)
        </Typography>
      </Box>
      <Box>
        <Grid container justifyContent={'flex-end'} alignItems={'center'} spacing={1}>
          {isError && <Grid xs={12} item>
            <Alert severity="error">{errorMessage}.</Alert>
          </Grid>}
          <Grid item>
            <ButtonComponent variant="outlined" onClick={handleCancel} name={"Cancel"} />
          </Grid>
          <Grid item>
            <ButtonComponent variant="contained" onClick={onClick} name={"Submit"} />
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};
