import React,{useState, useEffect} from "react";
import { IMAGES } from "../../../assets";
import { LogoCard } from "../../modules";
import { Container, } from "@mui/material";
import { CommonRepositoty } from "../../../repository";
import _ from "lodash";
import { baseURL } from "../../../repository/Repository";


export default function SlideShowComponent() {

  const [sliderList , setSliderList ]=useState([]);
  const [isError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(()=>{
   onLoadRetailer();
  },[])

  const onLoadRetailer =async()=>{
    const data = await CommonRepositoty.onGetRetailerList();
    if (data?.status_code == 1) {
        setError(false);
        setSliderList(_.get(data, 'data', []))
    } else {
        setError(true);
    }
    setErrorMessage(_.get(data, 'message', ''))
  }
  
  return (
    <>
      <main className="slider-main-container">
        <swiper-container
          navigation-next-el=".custom-next-button"
          navigation-prev-el=".custom-prev-button"
          autoplay-delay="2000"
          autoplay-disable-on-interaction="false"
          center-slides="true"
          breakpoints={JSON.stringify({
            640: {
              slidesPerView: 1,
              spaceBetween: 50,
            },

            768: {
              slidesPerView: 2,
              spaceBetween: 30,
            },

            1024: {
              slidesPerView: 3,
              spaceBetween: 50,
            },

            1440: {
              slidesPerView: 4,
              spaceBetween: 50,
            },
          })}
        >
          {sliderList?.map((item, index) => {
           
            return (
              <swiper-slide key={index + 1}>
                <LogoCard
                  image={baseURL+item?.image}
                  link={item?.url}
                  title={item?.name}
                />
              </swiper-slide>
            );
          })}
        </swiper-container>

        <div className="nav-btn custom-prev-button">
          {/* <!-- https://feathericons.dev/?search=arrow-left&iconset=feather --> */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="26"
            height="26"
            class="main-grid-item-icon-left"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="4"
          >
            <polyline points="15 18 9 12 15 6" />
          </svg>
        </div>

        <div className="nav-btn custom-next-button">
          {/* <!-- https://feathericons.dev/?search=arrow-right&iconset=feather --> */}

          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="26"
            height="26"
            class="main-grid-item-icon-right"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="4"
          >
            <polyline points="9 18 15 12 9 6" />
          </svg>
        </div>
      </main>
    </>
  );
}
