import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, Backdrop, CircularProgress, Alert } from "@mui/material";
import { ButtonComponent, InputComponent } from "../../../atoms";
import { theme } from "../../../../style/Theme/Theme";
import { AUTH_SCREEN_STATES, PHONE_REGEX, getText } from "../../../../utils/Credentials";
import { useNavigate } from "react-router-dom";
import { AuthRepository } from "../../../../repository";
import _ from "lodash"

export default function ForgotPasswordForm({ viewChange = () => { } }) {
  const [phone, setPhone] = useState('');
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [errorPhone, setPhoneError] = useState(false);
  const [phoneErrorMessage, setPhoneErrorMessage] = useState('');

  const [isResError, setResError] = useState(false);
  const [errorResMessage, setErrorResMessage] = useState('');
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };


  const onChangePhone = (e) => {
    const text = getText(e);
    const reg = PHONE_REGEX;
    let isValid = false;
    if (text.trim().length > 3) {
      isValid = reg.test(text.trim());
    }
    setPhone(text);
    setPhoneError(false);
    setIsPhoneValid(isValid)
  }

  const onResetPassword = async () => {
    setResError(false)
    if (isPhoneValid) {
      const response = await AuthRepository.onForgotPassword({ mobile: phone });
      handleOpen()
      if (response) {
        if (response?.status_code == 1) {
          // onClickSend("fps")
          viewChange(AUTH_SCREEN_STATES.VERIFY)
          // phone= data?.mobile
          localStorage.setItem('phone', phone);
        } else {
          setResError(true);
          setErrorResMessage(_.get(response, 'message', ''));
        }
        _.delay(handleClose, 5000)
      }
    } else {
      if (!isPhoneValid) {
        setPhoneError(true);
      }

    }
  };

  const onClickSend = (value) => {
    viewChange(value);
  }
  return (
    <Grid container flexDirection={"column"} spacing={2}>
      {isResError && <Grid item>
        <Alert severity="error">{errorResMessage}.</Alert>
      </Grid>}
      <Grid item>
        <Typography variant="h5" className="auth-header">
          Forgot your Password ?
        </Typography>
        <Box >
          <Typography variant="body2" color={theme.status.smallTextColor}>
            Please enter your registered phone number to reset your password.
            You’ll receive a text message or mail with a verification-code.
          </Typography>
        </Box>
      </Grid>

      <Grid item>
        <Grid container flexDirection={"column"} spacing={3}>
          <Grid item>
            <InputComponent
              isError={errorPhone}
              error={phone?.length > 0 ? 'Mobile is not valid' : 'Mobile is required'}
              onChange={onChangePhone}
              label='Phone Number'
              type={'text'} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container spacing={1}>
          <Grid item>
            <ButtonComponent
              size={"large"}
              variant="outlined"
              // onClick={() => viewChange("fpb")}
              onClick={() => viewChange(AUTH_SCREEN_STATES.LOGIN)}
              name={"Back"} />
          </Grid>
          <Grid item>
            <ButtonComponent
              size={"large"}
              variant="contained"
              onClick={onResetPassword}
              name={"Send"} />
          </Grid>
        </Grid>
      </Grid>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
}
