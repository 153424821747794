import Repository from "./Repository";
import _ from "lodash";

const getPath = (path) => {
    return `common/${path}`;
}
//Common API Calls
class AuthRepository extends Repository {
    onSignUp = async (params) => {
        try {
            const data = await this.postData('customer/registration', params);
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };


    onLogin = async (params) => {
        try {
            const data = await this.postData('common/login', params);
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };


    onForgotPassword = async (params) => {
        try {
            const data = await this.postData('common/forgot-password', params);
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

    onForgotPasswordVerify = async (params) => {
        try {
            const data = await this.postData('common/forgot-pwd-otp-verify', params);
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

    onResetPassword = async (params) => {
        try {
            const data = await this.postData('common/reset-password', params);
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

    onChangePassword = async (params) => {
        try {
            const data = await this.postData('common/change-password', params);
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

    onRegisterVerify = async (params) => {
        try {
            const data = await this.postData('customer/otp-verify', params);
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

    onResendRegisterVerificationCode = async (params) => {
        try {
            const data = await this.postData('customer/otp-send', params);
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

    onSignOut = async () => {
        try {
            const data = await this.postData('customer/sign-out');
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };
}

export default new AuthRepository("auth");

