import { colors, createTheme } from "@mui/material";
import "@fontsource/source-sans-pro";

export const theme = createTheme({
  status: {
    smallTextColor: "#A9A9A9",
    footerTextColor: "#cdbed9",
    whiteColor: "#fff",
  },
  forgotPwTxt: {
    cursor: "pointer",
  },
  palette: {
    primary: {
      main: "#572682",
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      contrastText: "#fff",
    },
    secondary: {
      main: "#572682",
      light: "#F5EBFF",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#572682    ",
    },
  },
  typography: {
    fontFamily: ["Source Sans Pro", "sans-serif"],
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  components: {
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          backgroundColor: "#572682",
        },
      },
    },
    MUIDataTableSelectCell: {
      styleOverrides: {
        root: {
          backgroundColor: 'white', // Default color
         
        },
      }
    }
  }
  
});
