import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";

export default ({ lable = '', value = '' }) => {
   
    return (
        <Grid
            item
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
            }}
        >
            <Typography
                fontFamily={"Source sans pro"}
                fontWeight={500}
            >
                {lable}
            </Typography>
            <Typography
                fontFamily={"Source sans pro"}
                fontWeight={500}
            >
                {value}
            </Typography>
        </Grid>
    )

}