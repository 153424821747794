import React from "react";
import {
  ActionRequiredExpandable,
  ReadyToSendExbandable,
  TransactionExpandable,
} from "../../organisms";
import { TableCell, TableRow } from "@mui/material";

export const ExpandableRowEntry = ({ rowData, rowMeta , onHandleBackDrop=()=>{}, onLoadDataAgain=()=>{}}) => {
  const colSpan = rowData.length + 1;
  

  return (
    <TableRow>
      <TableCell colSpan={colSpan}>
        {rowData[0] == "Ready To Send" ? <ReadyToSendExbandable onLoadDataAgain={onLoadDataAgain} onHandleBackDrop={onHandleBackDrop} data={rowData} readytoSend={true}/> : null}

        {rowData[0] == "Action Required" ? <ActionRequiredExpandable onLoadDataAgain={onLoadDataAgain} onHandleBackDrop={onHandleBackDrop} data={rowData} /> : null}

        {rowData[0] == "transactions" ? <TransactionExpandable/> : null}
      </TableCell>
    </TableRow>
  );
};
