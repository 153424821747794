import Repository from "./Repository";
import _ from "lodash";

const getPath = (path) => {
    return `customer/${path}`;
}
//Common API Calls
class UserRepository extends Repository {
    addAddress = async (params) => {
        try {
            const data = await this.postData(getPath('add-address'), params);
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

    editAddress = async (params) => {
        try {
            const data = await this.putData(getPath('update-address'), params);
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

    deleteAddress = async (id) => {
        try {
            const data = await this.deleteData(getPath(`delete-address/${id}`), {});
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };


    getAddressList = async (params) => {
        try {
            const data = await this.getData(getPath('all-addresses'), params);
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

    onChangeEmail = async (params) => {
        try {
            const data = await this.postData(getPath('change-email'), params);
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

}

export default new UserRepository("user");