import React from "react";
import { Avatar, Box, Stack } from "@mui/material";
import { Typography } from "@mui/material";
import { IMAGES } from "../../../../assets";

export const InfoMessage = ({ message }) => {
  return (
    <Box
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Stack spacing={2} alignItems={'center'}>
        <Avatar src={IMAGES.LOGO.INFO_ICON} alt="logo" sx={{ width: 54, height: "auto" }} />
        <Typography variant="subtitle1" fontWeight={600} textAlign={"center"} fontFamily={"Source Sans Pro"}>
          {message}
        </Typography>
      </Stack>
    </Box>
  );
};
