import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Alert, Backdrop, CircularProgress } from '@mui/material';
import { theme } from '../../../../style/Theme/Theme';
import { ButtonComponent, InputComponent } from '../../../atoms';
import _ from "lodash"
import { AUTH_SCREEN_STATES, getText } from '../../../../utils/Credentials';
import { AuthRepository } from '../../../../repository';


export default function VerifyOTPRegistraionForm({ viewChange = () => { }, onResponse=()=>{}  }) {

  const [isResError, setResError] = useState(false);
  const [errorResMessage, setErrorResMessage] = useState('');
  const [open, setOpen] = React.useState(false);

  const [otp, setOtp] = useState('');
  const [isOtpValid, setOtpValid] = useState(false);
  const [otpError, setOtpError] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

 

  const onVerify = async () => {
    setResError(false)
    if(isOtpValid){
      const mobile = localStorage.getItem('phone');
      handleOpen()
      const response = await AuthRepository.onRegisterVerify({mobile, otp});
      if (response) {
        if (response?.status_code == 1) {
          onResponse();
        } else {
          setResError(true);
          setErrorResMessage(_.get(response, 'message', ''));
        }
        handleClose()
      }else{
        handleClose() 
      }
    }else{
      setOtpError(true);
    }
  }

  const onResendOtp = async () => {
    setResError(false)
    if(isOtpValid){
      const mobile = localStorage.getItem('phone');
      handleOpen()
      const response = await AuthRepository.onResendRegisterVerificationCode({mobile});
      if (response) {
        if (response?.status_code == 1) {
    //  response
        } else {
          setResError(true);
          setErrorResMessage(_.get(response, 'message', ''));
        }
        handleClose()
      }else{
        handleClose() 
      }
    }
  }


  const onChangeText = (e) => {
    const text = getText(e);
    if (text.length <= 6) {
      setOtp(text)
    }
    setOtpValid(text?.length == 6)
    setOtpError(false)
  }
  
  return (
    <Grid container flexDirection={"column"} spacing={2}>
      {isResError && <Grid item>
        <Alert severity="error">{errorResMessage}.</Alert>
      </Grid>}
      <Grid item >
        <Typography variant="h5" className="auth-header">
          Verify OTP
        </Typography>
        <Box >
          <Typography variant="body2" color={theme.status.smallTextColor}>
            Please enter the one time password (OTP) that we have sent to your mobile number.
          </Typography>
        </Box>

      </Grid>

      <Grid item>
        <Grid container flexDirection={"column"} spacing={3}>
          <Grid item>
            <InputComponent
              value={otp}
              isError={otpError}
              error={otp?.length == 0 ? 'Please enter otp' : 'Otp is invalid'}
              onChange={onChangeText}
              size="medium"
              label="One Time Password"
              type={"number"} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container spacing={1}>
          <Grid item>
            <ButtonComponent size={"large"} variant="outlined"
            onClick={() => viewChange(AUTH_SCREEN_STATES.FORGOTPASSWORD)}
            //  onClick={() => viewChange("otpb")}
              name={"Back"} />
          </Grid>
          <Grid item>
            <ButtonComponent size={"large"} variant="contained" onClick={onResendOtp} name={"Resend OTP"} />
          </Grid>
          <Grid item>
            <ButtonComponent size={"large"} variant="contained" onClick={onVerify} name={"Verify"} />
          </Grid>
        </Grid>
      </Grid>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  )
}
