import React, { Fragment, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
  Alert,
  CircularProgress,
  Backdrop,
  Card,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ButtonComponent from "../../../atoms/Buttons/ButtonComponent";
import { InputComponent, PopupComponent } from "../../../atoms";
import { ViewPhoto } from "../ViewPhoto/ViewPhoto";
import { RequestPhotoOptions } from "../RequestPhotoOptions/RequestPhotoOptions";
import _ from "lodash";
// import PageviewIcon from "@mui/icons-material/Pageview";
import { PhotoAlbum, Add, Cancel } from "@mui/icons-material";
import { getText } from "../../../../utils/Credentials";
import { DashBoardRepository } from "../../../../repository";
// import { AlertConfirmation } from "../AlertConfirmation/AlertConfirmation";
import { SuccessMessage } from "../SuccessMessage/SuccessMessage";

export const ReadyToSendExbandable = ({
  data,
  onHandleBackDrop = () => {},
  onLoadDataAgain = { onLoadDataAgain },
}) => {
  const [viewSinglePhoto, setViewSinglePhoto] = useState(false);
  const [photoPopUp, setPhotoPopUp] = React.useState(false);
  const [selectedItem, setSelectedItem] = useState({ image: "", fileName: "" });

  const [isEdit, setIsEdit] = useState(false);
  const [openPopupIndex, setOpenPopUpIndex] = useState(null);

  const [list, setList] = useState([]);
  const [allData, setAllData] = useState({});
  const [packages, setPackages] = useState([]);

  const [price, setPrice] = useState("");
  const [priceError, setPriceError] = useState(false);
  const [isPriceValid, setIsPriceValid] = useState(false);

  const [isResError, setResError] = useState(false);
  const [errorResMessage, setErrorResMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [isExpandableEmpty, setIsExpandableEmpty] = useState(true);

  const onChangePrice = (e) => {
    const text = getText(e);
    const isValid = text && text > 0;
    setPrice(text);
    setIsPriceValid(isValid);
    setPriceError(false);
  };

  useEffect(() => {
    setList(_.get(data, "[2]", []));
    const item = _.get(data, "[3].item");
    const packageItemDetails = _.get(data, "[3].packageItemDetails");
    console.log("data", data, )
    setPackages(packageItemDetails);
    setAllData({ ...item, packageItemDetails });
  }, [data]);

  useEffect(() => {
    // setPrice(_.get(allData, "totalValue", "0.00"));
  }, [allData]);

  const onClose = () => {
    setViewSinglePhoto(false);
    setSelectedItem({ image: "", fileName: "" });
  };

  const onAdd = async (id, currency) => {
    setResError(false);
    // const packageData = _.get(allData, "packageItemDetails[0]", {});
    if (isPriceValid) {
      const params = {
        declared_value: price,
        item_id: id,
        package_id: _.get(allData, "packageId", 0),
        currency: currency,
      };
      onHandleBackDrop(true);
      const response = await DashBoardRepository.onUpdatePackagePrice(params);
      if (response) {
        if (response?.status_code == 1) {
          onLoadDataAgain(response);
          setIsEdit(false);
          setSuccessMsg(response?.message);
          setIsSuccess(true);
        } else {
          setResError(true);
          setErrorResMessage(_.get(response, "message", ""));
          // console.log("error response", response);
        }
        onHandleBackDrop(false);
      }
    } else {
      setPriceError(true);
    }
  };

  const handleEdit = (value, valuePerUnit) => {
    setOpenPopUpIndex(value);
    setPrice(valuePerUnit);
    setIsEdit(true);
  };
  const handleEditClose = (value) => {
    setOpenPopUpIndex(value);
    setIsEdit(false);
  };

  return (
    <>

      <Stack spacing={1}>
        <Typography
          variant="h6"
          fontFamily={"Source Sans Pro"}
          fontWeight={600}
          textTransform={"uppercase"}
        >
          Package Details
        </Typography>

        <Typography variant="subtitle1" fontFamily={"Source Sans Pro"}>
          <span style={{ fontFamily: "Source Sans Pro", fontWeight: 600 }}>
            To:
          </span>
          {` ${_.get(allData, "customerName", " ")}`}
        </Typography>
        {_.map(packages, (dataItem, index) => {
          return (
            <Box
              key={index + 1}
              sx={{
                paddingInline: 2,
                p: 1,
                borderRadius: 2,
                bgcolor: "#C8ACD630",
              }}
            >
              <Grid
                container
                spacing={2}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
              >
                <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
                  <Typography
                    variant="subtitle2"
                    fontFamily={"Source Sans Pro"}
                  >
                    <span
                      style={{ fontFamily: "Source Sans Pro", fontWeight: 600 }}
                    >
                      Description:
                    </span>
                  </Typography>

                  <Typography
                    variant="subtitle2"
                    fontFamily={"Source Sans Pro"}
                  >
                    {_.get(dataItem, "Description", "")}
                  </Typography>
                </Grid>
                <Grid item xl={2} lg={2} md={2} sm={2} xs={6}>
                  <Typography
                    variant="subtitle2"
                    fontFamily={"Source Sans Pro"}
                  >
                    <span
                      style={{ fontFamily: "Source Sans Pro", fontWeight: 600 }}
                    >
                      Qty:
                    </span>
                    <br />
                    {_.get(dataItem, "NumberOfPieces", "")}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xl={3}
                  lg={3}
                  md={3}
                  sm={3}
                  xs={6}
                  sx={{ position: "relative" }}
                >
                  {isEdit && index === openPopupIndex ? (
                    <Box
                      sx={{ position: "absolute", borderRadius:2, borderStyle:'solid', borderWidth:1, borderColor:'#00000030', width: 250,p:2, bgcolor:'#ffffff',zIndex:2, height: "auto"}}
                    >
                      <Grid
                        item 
                      >
                        <InputComponent
                          isError={priceError}
                          error={
                            price < 0
                              ? "Number is not valid"
                              : "Number is required"
                          }
                          onChange={onChangePrice}
                          label="Total Line Value"
                          type={"number"}
                          value={price}
                        />
                        <Grid
                          style={{ marginTop: "1px" }}
                          spacing={2}
                          container
                        >
                          <Grid item>
                            <Box>
                              <ButtonComponent
                                variant="contained"
                                name={"Add"}
                                onClick={() => onAdd(_.get(dataItem,"ItemId"), _.get(dataItem,"Currency"))}
                              />
                            </Box>
                          </Grid>
                          <Grid item>
                            <Box>
                              <ButtonComponent
                                variant="outlined"
                                name={"Cancel"}
                                onClick={() => {
                                  handleEditClose(index);
                                }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                        {isResError && (
                          <Grid xs={12} item>
                            <Alert severity="error">{errorResMessage}.</Alert>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  ) : (
                    <Grid container sx={{ alignItems: "flex-start", gap: 2 }}>
                      <Grid item>
                        <Typography
                          variant="subtitle2"
                          fontFamily={"Source Sans Pro"}
                        >
                          <span
                            style={{
                              fontFamily: "Source Sans Pro",
                              fontWeight: 600,
                            }}
                          >
                            Value Per Unit:
                          </span>
                          <br />$ {_.get(dataItem, "ItemValueInCurrency", "")}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <IconButton
                          onClick={() => {
                            handleEdit(index, _.get(dataItem, "ItemValueInCurrency", ""));
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  )}
                </Grid>

                <Grid item xl={2} lg={2} md={2} sm={2} xs={6}>
                  <Typography
                    variant="subtitle2"
                    fontFamily={"Source Sans Pro"}
                  >
                    <span
                      style={{
                        fontFamily: "Source Sans Pro",
                        fontWeight: 600,
                      }}
                    >
                      Total Line Value:
                    </span>
                    <br />${" "}
                    {_.get(dataItem, "NumberOfPieces", "") *
                      _.get(dataItem, "ItemValueInCurrency", "")}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          );
        })}

        <Box>
          <ButtonComponent
            variant="contained"
            name={"Photo"}
            onClick={() => setPhotoPopUp(true)}
          />
        </Box>
        {/* <Box>
          <Grid
            container
            spacing={2}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <Typography variant="subtitle2" fontFamily={"Source Sans Pro"}>
                To: {_.get(allData, "customerName", "")}
              </Typography>
            </Grid>
            <Grid item xl={2} lg={2} md={2} sm={2} xs={6}>
              <Typography variant="subtitle2" fontFamily={"Source Sans Pro"}>
                Qty:{" "}
                {_.get(allData, "packageItemDetails[0].NumberOfPieces", "")}
              </Typography>
            </Grid>
            <Grid item xl={2} lg={2} md={2} sm={2} xs={6}>
              <Typography variant="subtitle2" fontFamily={"Source Sans Pro"}>
                Value Per Unit(
                {_.get(allData, "packageItemDetails[0].Currency", "")}):{" "}
                {_.get(
                  allData,
                  "packageItemDetails[0].ItemValueInCurrency",
                  ""
                )}
              </Typography>
            </Grid>

            {!isEdit ? (
              <Grid item xl={2} lg={2} md={2} sm={2} xs={6}>
                <Typography variant="subtitle2" fontFamily={"Source Sans Pro"}>
                  Total Line Value(
                  {_.get(allData, "packageItemDetails[0].Currency", "")}):
                  {_.get(allData, "totalValue", "0.00")}
                </Typography>
              </Grid>
            ) : (
              <Grid
                style={{ paddingRight: "1px" }}
                item
                xl={4}
                lg={4}
                md={4}
                sm={4}
                xs={12}
              >
                <InputComponent
                  isError={priceError}
                  error={
                    price < 0 ? "Number is not valid" : "Number is required"
                  }
                  onChange={onChangePrice}
                  label="Total Line Value"
                  type={"number"}
                  value={price}
                />
                <Grid style={{ marginTop: "1px" }} spacing={2} container>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <ButtonComponent
                        variant="contained"
                        name={"Add"}
                        onClick={onAdd}
                      />
                    </Box>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <ButtonComponent
                        variant="outlined"
                        name={"Cancel"}
                        onClick={() => {
                          setIsEdit(false);
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
                {isResError && (
                  <Grid xs={12} item>
                    <Alert severity="error">{errorResMessage}.</Alert>
                  </Grid>
                )}
              </Grid>
            )}
            {!isEdit ? (
              <Grid item xl={2} lg={2} md={2} sm={2} xs={12}>
                <IconButton
                  onClick={() => {
                    setIsEdit(true);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Grid>
            ) : null}
          </Grid>
          <Box>
            <ButtonComponent
              variant="contained"
              name={"Photo"}
              onClick={() => setPhotoPopUp(true)}
            />
          </Box>
        </Box> */}
        <Divider />

        <Typography
          variant="h6"
          fontFamily={"Source Sans Pro"}
          fontWeight={600}
          textTransform={"uppercase"}
        >
          Recived Photos
        </Typography>

        <Grid container spacing={1}>
          {_.map(list, (item, index) => {
            const image =
              `data:image/png;base64,` + _.get(item, "Document", "");
            const fileName = _.get(item, "FileName", "");

            return (
              <Grid
                item
                xl={2}
                lg={2}
                md={2}
                sm={2}
                xs={3}
                key={index + 1}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  if (fileName || fileName?.length > 0) {
                    setViewSinglePhoto(true);
                    setSelectedItem({ image, fileName });
                  }
                }}
              >
                {!fileName || fileName?.length <= 0 ? (
                  <Avatar sx={{ width: "100%", height: 80, borderRadius: 2 }}>
                    <PhotoAlbum />
                  </Avatar>
                ) : (
                  <Avatar
                    src={image}
                    sx={{ width: "100%", height: 80, borderRadius: 2 }}
                  />
                )}
              </Grid>
            );
          })}
        </Grid>
      </Stack>

      <PopupComponent
        open={viewSinglePhoto}
        handleClose={onClose}
        handleAction={onClose}
        maxWidth={"md"}
        title={"Image_name.jpg"}
        showBottomBtns={false}
        showSecondaryBtn={false}
        content={<ViewPhoto data={selectedItem} />}
      />

      <PopupComponent
        open={photoPopUp}
        handleClose={() => setPhotoPopUp(false)}
        handleAction={() => setPhotoPopUp(false)}
        maxWidth={"md"}
        title={""}
        showBottomBtns={false}
        showSecondaryBtn={false}
        content={<RequestPhotoOptions />}
      />

      <PopupComponent
        open={isSuccess}
        handleAction={() => setIsSuccess(false)}
        handleClose={() => setIsSuccess(false)}
        maxWidth={"xs"}
        title={"Success"}
        showBottomBtns={true}
        actionButtonName={"Ok"}
        showSecondaryBtn={false}
        content={<SuccessMessage message={successMsg} />}
      />
    </>
  );
};
