import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";

export const ProhibitedItems = () => {
  return (
    <Stack spacing={2}>
      <Box>
        <Typography
          variant="subtitle1"
          fontFamily={"Source Sans Pro"}
          color={"GrayText"}
          fontWeight={500}
        >
          There are some items we cannot legally ship due to customs laws and
          delivery company or airline restrictions.
        </Typography>
      </Box>

      <Grid container >
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <Box>
            <ul
            //   type="a"
              style={{
                fontFamily: "Source Sans Pro",
                fontWeight: 500,
                color: "GrayText",
              }}
            >
              <li style={{ fontWeight: 600 }}>
                Combustible and flammable items (paints, oils, lighters,
                perfume, nail polish).
              </li>

              <li style={{ fontWeight: 600 }}>Alcohol</li>
              <li style={{ fontWeight: 600 }}>Any type of pressurised can.</li>
              <li style={{ fontWeight: 600 }}>Illegal drugs and narcotics.</li>
              <li style={{ fontWeight: 600 }}>
                Hazardous materials (lithium batteries, dry ice, matches,
                chemicals, explosives, etc.).
              </li>

              <li style={{ fontWeight: 600 }}>
                Cash, currency, money orders, cashier’s checks, bank drafts,
                bearer bonds, jewellery and precious stones.{" "}
              </li>
            </ul>
          </Box>
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <Box>
            <ul
            //   type="a"
              style={{
                fontFamily: "Source Sans Pro",
                fontWeight: 500,
                color: "GrayText",
              }}
            >
              <li style={{ fontWeight: 600 }}>
                Firearms, weaponry and their parts.
              </li>

              <li style={{ fontWeight: 600 }}>
                Lottery tickets and gambling devices.
              </li>
              <li style={{ fontWeight: 600 }}>
                Agricultural products such as tobacco, plants and seeds or
                coffee.
              </li>
              <li style={{ fontWeight: 600 }}>
                Prescription and/or veterinary medication.
              </li>
              <li style={{ fontWeight: 600 }}>
                Perishable foods (refrigerated, frozen, fresh/raw fruits and
                vegetables, etc.).
              </li>

              <li style={{ fontWeight: 600 }}>Pornography</li>
              <li style={{ fontWeight: 600 }}>
                Animals and products made with animal skin (furs).
              </li>
            </ul>
          </Box>
        </Grid>
      </Grid>
      <Box>
        <Typography
          variant="subtitle1"
          fontFamily={"Source Sans Pro"}
          color={"GrayText"}
          fontWeight={500}
        >
          As stated in our Terms and Conditions, as the importer of record into
          the destination country, you are responsible for ensuring that any and
          all items that you export from the United States using the services
          provided by PrestoWorldwide.com comply with all laws and regulations
          of the United States and Sri Lanka.
        </Typography>
      </Box>
    </Stack>
  );
};
