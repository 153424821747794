import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import {
  ButtonComponent,
  DropdownComponent,
  PopupComponent,
  TableComponent,
} from "../../components/atoms";
import { AddressCard, ShippingDetailsCard } from "../../components/modules";
import { useLocation } from "react-router-dom";
import {
  CommonRepositoty,
  DashBoardRepository,
  ShipmentRepository,
  UserRepository,
} from "../../repository";
import _, { get, partialRight } from "lodash";
import { ErrorMessage } from "../../components/organisms";

export const PaymentPage = () => {
  const location = useLocation();
  const [selectedItems, setSelectedItems] = useState(
    _.get(location, "state.selectedItems")
  );
  const [addressList, setAddressList] = useState([]);
  const [addressListDropdown, setAddressListDropdown] = useState([]);
  const [SelectedAddress, setSelectedAddress] = useState({});
  const [selectedAddressId, setSelectedAddressId] = useState(-1);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [promoCode, setPromoCode] = useState("");
  const [promoCodeError, setPromoCodeError] = useState(false);
  const [promoCodeErrorMessage, setPromoCodeErrorMessage] = useState("");
  const promoDetails = useRef({});
  const [shippingChargers, setShippingChargers] = useState([]);
  const [shippingCost, setShippingCost] = useState(
    _.get(location, "state.totalCost")
  );
  const [weightUnit, setWeightUnit] = useState(
    _.get(location, "state.weightUnit")
  );
  const [check, setCheck] = useState(false);
  const [radioButton, setRadioButton] = useState("");
  const totalAddonCost = useRef(0);
  // const [totalAddonCost, setTotalAddonCost] = useState(0);
  const [isResError, setResError] = useState(false);
  const [errorResMessage, setErrorResMessage] = useState("");

  useEffect(() => {
    getUserAddresses();
    getActivePaymentGateways();
    getAdditionalShippingChargers();
  }, []);

  const column = [
    {
      name: "packageStatus",
      label: "State",
      options: {
        display: false,
      },
    },
    {
      name: "etailerName",
      label: "From",
    },
    {
      name: "packageId",
      label: "Package ID",
    },
    {
      name: "total",
      label: "Merchandise Value",
    },

    {
      name: "weightWithUnit",
      label: "Weight",
    },

    {
      name: "id",
      label: "Remove",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <ButtonComponent
            variant="outlined"
            name={"remove"}
            onClick={() => handleRemove(value)}
          />
        ),
      },
    },

    // {
    //   name: "packageArrivalDateAtWarehouse",
    //   label: "From",
    //   options: {
    //     customHeadLabelRender: ({ display, column }) => {
    //       return (
    //         <Box
    //           sx={{
    //             display: "flex",
    //             justifyContent: "center",
    //             alignItems: "center",
    //           }}
    //         >
    //           <AccessAlarmIcon />
    //         </Box>
    //       );
    //     },
    //     customBodyRender: (value, tableMeta, updateValue) => (
    //       <p>{dateCount(value)}</p>
    //     ),
    //   },
    // },
  ];

  const handleAdditionalCost = (index, value) => {
    const checked = _.get(index, "target.checked");
    let add = 0;

    if (checked) {
      let total = parseFloat(shippingCost) + value;
      setShippingCost(total);
      totalAddonCost.current += value;
    } else {
      let total = parseFloat(shippingCost) - value;
      setShippingCost(total);
      totalAddonCost.current -= value;
    }
  };

  const getShippingCost = async (totalWeight, unit) => {
    const response = await CommonRepositoty.onGetShippingCost({
      packageFrom: "USA",
      unit: unit,
      weight: totalWeight,
    });
      if (response?.status_code == 1) {
        let newShippingPrice = parseFloat(
          _.get(response, "data.rates[0].shippingCost", [])
        );
        if (totalAddonCost.current <= 0 && _.isEmpty(promoDetails.current)) {
          setShippingCost(newShippingPrice);
        }
        if (totalAddonCost.current > 0) {

          let total = newShippingPrice + totalAddonCost.current;
          setShippingCost(total);
        }
        if (!_.isEmpty(promoDetails.current)) {
          if (_.get(promoDetails.current, "type_name", {}) == "flat") {
            let discounted =
              shippingCost -
              parseFloat(_.get(promoDetails.current, "value", {}));
            setShippingCost(discounted);
          }
          if (_.get(promoDetails.current, "type_name", {}) == "percentage") {
            let percentage =
              (parseFloat(_.get(promoDetails.current, "value", {})) / 100) *
              parseFloat(shippingCost);
            let discounted = parseFloat(shippingCost) - percentage;
            setShippingCost(discounted);
          }
        }
      } else {
        setResError(true);
        setErrorResMessage(_.get(response, 'message', ''));
        // console.log("error response", response);
      }
    
  };

  const handleRemove = (id) => {
    const updatedList = _.filter(selectedItems, (item) => {
      if (_.get(item, "id") !== id) {
        return item;
      }
    });

    let weightt = 0;
    _.forEach(updatedList, (item) => {
      weightt += parseFloat(item?.weight);
    });
    getShippingCost(weightt, weightUnit);

    setSelectedItems(updatedList);
  };

  const onChanagePromoCode = (value) => {
    setPromoCode(_.get(value, "target.value"));
  };

 

  const onSelectAddress = (value) => {
    setSelectedAddressId(value?.target?.value);
    const address = _.filter(addressList, (item, index) => {
      if (item.id === value?.target?.value) {
        return item;
      }
    });
    setSelectedAddress(address[0]);
  };

  const getUserAddresses = async () => {
    const response = await UserRepository.getAddressList("");
      if (response?.status_code == 1) {
        const list = _.map(_.get(response, "data", []), (item, index) => {
          return { id: item.id, name: item.lable };
        });
        setAddressList(_.get(response, "data", []));
        setAddressListDropdown(list);
      } else {
        setResError(true);
        setErrorResMessage(_.get(response, 'message', ''));
        // console.log("error response", response);
      }
    
  };

  const getActivePaymentGateways = async () => {
    const response = await DashBoardRepository.onGetActivePaymentGateways();
      if (response?.status_code == 1) {
        setPaymentMethods(_.get(response, "data", []));
      } else {
        setResError(true);
        setErrorResMessage(_.get(response, 'message', ''));
        // console.log("error response", response);
      }
   
  };

  const getAdditionalShippingChargers = async () => {
    const response = await ShipmentRepository.onGetAdditionalShippingChargers();
      if (response?.status_code == 1) {
        setShippingChargers(_.get(response, "data", []));
      } else {
        setResError(true);
        setErrorResMessage(_.get(response, 'message', ''));
        // console.log("error response", response);
      }
    
  };

  const checkPromoCode = async (promoCode) => {
    const response = await ShipmentRepository.checkPromoCode({
      promo_code: promoCode,
    });
    if (response?.status_code == 1) {
      setCheck(true);
      promoDetails.current = _.get(response, "data", {});
      if (_.get(response, "data.type_name", {}) == "flat") {
        let discounted =
          shippingCost - parseFloat(_.get(response, "data.value", {}));
        setShippingCost(discounted);
      }
      if (_.get(response, "data.type_name", {}) == "percentage") {
        let percentage =
          (parseFloat(_.get(response, "data.value", {})) / 100) * shippingCost;
        let discounted = shippingCost - percentage;
        setShippingCost(discounted);
      }
    } else {
      setResError(true);
      setErrorResMessage(_.get(response, "message", ""));
    }
  };

  const roundToDecimalPlace = (number, decimalPlaces) => {
    const factor = Math.pow(10, decimalPlaces);
    return Math.round(number * factor) / factor;
  };

  const onClickCheckBtn = () => {
    if (promoCode == "" || promoCode == null) {
      setPromoCodeError(true);
      setPromoCodeErrorMessage("Promo code not valid, please try again");
    } else {
      setPromoCodeError(false);
      checkPromoCode(promoCode);
    }
  };

  const handleChangeRadio = (value) => {
    setRadioButton("comm");
  };

  return (
    <Container sx={{ pt: 5, pb: 5 }}>
      <Grid container spacing={2}>
        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
          <Stack>
            <TableComponent
              title="Packages in this Shipment"
              columns={column}
              data={selectedItems}
              selectableRows={false}
            />

            <Grid container mt={3}>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <DropdownComponent
                  label="Shipping To"
                  value={selectedAddressId}
                  dropdownList={addressListDropdown}
                  disabled={false}
                  onChange={onSelectAddress}
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              mt={2}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Grid item>
                {selectedAddressId > -1 ? (
                  <AddressCard
                    item={SelectedAddress}
                    showBackBtn={false}
                    showRadio={false}
                  />
                ) : null}
              </Grid>
              <Grid item>
                <Box p={1}>
                  <Stack spacing={1}>
                    <Typography
                      variant="h6"
                      fontWeight={600}
                      fontFamily={"Source Sans Pro"}
                    >
                      Estimated Shipping Cost
                    </Typography>
                    <Box>
                      <Typography variant="h6" fontFamily={"Source Sans Pro"}>
                        ${roundToDecimalPlace(shippingCost, 2)}
                      </Typography>
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <ButtonComponent
                          variant="contained"
                          name={"Confirm & Pay"}
                        />
                        <ButtonComponent variant="outlined" name={"Cancel"} />
                      </Box>
                    </Box>
                    <Typography
                      variant="caption"
                      fontFamily={"Source Sans Pro"}
                      color={"GrayText"}
                    >
                      Note - Please select a payment method to continue
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Stack>
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <ShippingDetailsCard
            promoValue={promoCode}
            promoCodeError={promoCodeError}
            promoCodeErrorMessage={promoCodeErrorMessage}
            onChangePromo={(value) => onChanagePromoCode(value)}
            shippingCost={shippingCost}
            paymentMethods={paymentMethods}
            otherOptions={shippingChargers}
            handleAdditionalCost={handleAdditionalCost}
            onClickCheck={onClickCheckBtn}
            isDiscount={check}
            promotion={promoDetails.current}
            radioButton={radioButton}
            handleChangeRadio={(value) => handleChangeRadio(value)}
          />
        </Grid>
      </Grid>

      <PopupComponent
        maxWidth={"md"}
        actionButtonName={"OK"}
        content={<ErrorMessage message={errorResMessage} />}
        showSecondaryBtn={false}
        open={isResError}
        title={"Error"}
        handleAction={() => setResError()}
      />
    </Container>
  );
};
