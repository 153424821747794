import axios from "axios";
import CREDENTIALS from "./Credentials";


const instance = axios.create({
  baseURL: CREDENTIALS.BASE_URI,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

const AXIOS_INSTANCE = {
  instance,
};

export default AXIOS_INSTANCE;
