import React, { useImperativeHandle, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
  Backdrop,
  CircularProgress,
  Alert,
} from "@mui/material";

import {
  ButtonComponent,
  PopupComponent,
  RadioButtonComponent,
} from "../../atoms";
import _ from "lodash";
import { AlertConfirmation, EditAddress } from "../../organisms";

export default ({
  showRadio = true,
  item,
  showBackBtn = true,
  onGetAddressList = () => {},
  onRemoveAddress = () => {},
}) => {
  const [editAddress, setEditAddress] = useState(false);
  const addressRef = useRef();
  const [confirmView, setConfirmView] = useState(false);

  const onPress = () => {
    addressRef.current.onPress();
  };

  // useImperativeHandle(ref, () => ({
  //   onPress() {
  //     onAddAddressUpdate();
  //   },
  // }));

  const onUpdate = () => {
    setEditAddress(false);
    onGetAddressList();
  };

  const onRemove = async () => {
    setConfirmView(false);
    onRemoveAddress(item?.id);
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Card sx={{ borderRadius: 3, minWidth:240 }} variant="outlined">
          <CardContent sx={{ height: 240 }}>
            <Grid container justifyContent={"space-between"}>
              <Grid item>
                <Typography gutterBottom variant="h5" component="div">
                  {_.get(item, "lable", "")}
                </Typography>
              </Grid>
              {showRadio ? (
                <Grid item>
                  <RadioButtonComponent />
                </Grid>
              ) : null}
            </Grid>

            <Typography
              variant="body2"
              fontFamily={"Source Sans Pro"}
              color="text.secondary"
            >
              {`${_.get(item, "first_name", "")} ${_.get(
                item,
                "last_name",
                ""
              )} `}
              <br></br>
              <Typography
                sx={{
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  WebkitLineClamp: 2, // Limit to 2 lines
                  maxHeight: "3em", // Adjust the height to fit 2 lines (assuming 1.5em per line)
                }}
                title={_.get(item, "address_line_one", "")}
                variant="body2"
                fontFamily={"Source Sans Pro"}
                color="text.secondary"
              >
                {`Address 01 : ${_.get(item, "address_line_one", "")}`}
              </Typography>

              <Typography
                sx={{
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  WebkitLineClamp: 2, // Limit to 2 lines
                  maxHeight: "3em", // Adjust the height to fit 2 lines (assuming 1.5em per line)
                }}
                title={_.get(item, "address_line_two", "")}
                variant="body2"
                fontFamily={"Source Sans Pro"}
                color="text.secondary"
              >
                {`Address 02 : ${_.get(item, "address_line_two", "")}`}
              </Typography>
              {`${_.get(item, "city.name", "")} ,  `}
              <br></br>
              {`${_.get(item, "district.name", "")} , `}
              <br></br>
              {`${_.get(item, "province.name", "")} ,  `}
              <br></br>
              {`${_.get(item, "zip_code", "")} ,`}
              <br></br>
              {`Contact : ${_.get(item, "mobile", "")} ,`}
              <br></br>
            </Typography>
          </CardContent>
          <CardActions>
            <Grid container justifyContent={"space-between"} spacing={1}>
              <Grid item xs={showBackBtn ? 6 : 12}>
                <ButtonComponent
                  name={"Edit"}
                  variant="contained"
                  width={"100%"}
                  onClick={() => setEditAddress(true)}
                />
              </Grid>
              {showBackBtn ? (
                <Grid item xs={showBackBtn ? 6 : 12}>
                  <ButtonComponent
                    onClick={() => {
                      setConfirmView(true);
                    }}
                    width={"100%"}
                    name={"Delete"}
                  />
                </Grid>
              ) : null}
            </Grid>
          </CardActions>
        </Card>
      </Box>
      <PopupComponent
        maxWidth={"md"}
        actionButtonName={"Edit Address"}
        content={
          <EditAddress item={item} ref={addressRef} onPassResponse={onUpdate} />
        }
        secondaryBtnName={"Cancel"}
        open={editAddress}
        title={"Edit Address"}
        handleClose={() => setEditAddress(false)}
        handleAction={() => onPress()}
      />

      <PopupComponent
        maxWidth={"xs"}
        actionButtonName={"Yes"}
        content={
          <AlertConfirmation
            message={`Are you sure, you want to remove ${item?.lable} address?`}
          />
        }
        secondaryBtnName={"No"}
        open={confirmView}
        title={"Remove a Address"}
        handleClose={() => setConfirmView(false)}
        handleAction={onRemove}
      />
    </>
  );
};
